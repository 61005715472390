import React, { useState } from "react";
import axios from "axios";
import "../../assets/OnlineVoiceRecordToolAssets/App.css";

const VoiceRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [recordingUrl, setRecordingUrl] = useState(null);
  const [recordingId, setRecordingId] = useState(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Check if "audio/wav" MIME type is supported
      const mimeType = MediaRecorder.isTypeSupported("audio/wav")
        ? "audio/wav"
        : "audio/webm";

      const recorder = new MediaRecorder(stream, { mimeType });
      const chunks = [];

      recorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      recorder.onstop = async () => {
        // Explicitly create a Blob with the correct MIME type
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        setAudioBlob(audioBlob);

        // Create an object URL for playback
        const url = URL.createObjectURL(audioBlob);
        setAudioUrl(url);

        // Save the recording to the server
        await saveRecording(audioBlob);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setAudioChunks(chunks);
      setIsRecording(true);
      setIsPaused(false);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      setIsPaused(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && (mediaRecorder.state === "recording" || mediaRecorder.state === "paused")) {
      mediaRecorder.stop();
      setIsRecording(false);
      setIsPaused(false);
    }
  };

  const saveRecording = async (audioBlob) => {
    const formData = new FormData();

    formData.append("file", audioBlob, "recording.wav");

    // Debug: log formData contents
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }

    try {
      const response = await axios.post("http://127.0.0.1:8000/api/audio/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRecordingUrl(response.data.url);
      alert(`Recording saved! Shareable URL: ${response.data.url}`);
    } catch (error) {
      console.error("Error saving recording:", error);

      if (error.response && error.response.data) {
        alert(`Error: ${error.response.data.message}`);
        console.error("Server Response:", error.response.data);
      }
    }
  };

  const shareRecording = async () => {
    if (!recordingUrl) {
      alert("Please save the recording first!");
      return;
    }

    const uniqueId = `rec-${Date.now()}`;
    const currentDateTime = new Date().toISOString();

    const payload = {
      id: uniqueId,
      url: recordingUrl,
      timestamp: currentDateTime,
    };

    try {
      await axios.post("http://localhost:4000/api/shareRecording", payload);
      setRecordingId(uniqueId);
      alert(`Recording shared successfully! ID: ${uniqueId}`);
    } catch (error) {
      console.error("Error sharing recording:", error);
    }
  };

  const downloadRecording = () => {
    if (!audioBlob) {
      alert("No recording available for download.");
      return;
    }

    const downloadUrl = URL.createObjectURL(audioBlob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "recording.wav";
    a.click();
    URL.revokeObjectURL(downloadUrl);
  };

  return (
    <section>
      <div className="container">
        <div className="voice-recorder">
          <h2>Voice Recorder</h2>

          <div className="record-buttons">
            <button
              className={`record-button ${isRecording ? "disabled-button" : ""}`}
              onClick={startRecording}
              disabled={isRecording}
            >
              {isRecording ? "🎙️ Recording..." : "🎤 Record"}
            </button>

            <button
              className="pause-button"
              onClick={isPaused ? resumeRecording : pauseRecording}
              disabled={!isRecording}
            >
              {isPaused ? "▶️ Resume" : "⏸️ Pause"}
            </button>

            <button
              className="stop-button"
              onClick={stopRecording}
              disabled={!isRecording}
            >
              ⏹️ Stop
            </button>
          </div>

          {audioUrl && (
            <div className="audio-playback">
              <h3>Listen to your recording:</h3>
              <audio controls>
                <source src={audioUrl} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}

          {recordingUrl && (
            <div className="share-download-section">
              <button className="share-button" onClick={shareRecording}>
                🔗 Share Recording
              </button>

              <button className="download-button" onClick={downloadRecording}>
                ⬇️ Download Recording
              </button>

              {recordingId && (
                <div className="record-url">
                  <p>
                    Recording ID: <strong>{recordingId}</strong>
                  </p>
                  <p>
                    URL:{" "}
                    <a href={recordingUrl} target="_blank" rel="noopener noreferrer">
                      {recordingUrl}
                    </a>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default VoiceRecorder;