import React, { useState } from 'react';
import TimeZone from '../../components/CalendarShareLinkToolComponents/TimeZone';

// Style
import '../../assets/CalendarShareLinkToolAssets/App.css';

const EventForm = () => {
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    location: '',
    startDate: '',
    endDate: '',
    timezone: 'UTC +05:00 Asia/Karachi',
    customLink: '',
    isOnline: false,
    isAllDay: false,
  });

  const [submittedEvents, setSubmittedEvents] = useState([]);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  // Handle form change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEventData({
      ...eventData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Function to generate calendar event links
  const generateCalendarLinks = (event) => {
    const { title, startDate, endDate, location, description } = event;
    const start = new Date(startDate).toISOString().replace(/[-:.]/g, '');
    const end = new Date(endDate).toISOString().replace(/[-:.]/g, '');

    // Calendar URLs
    const googleLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(title)}&dates=${start}/${end}&location=${encodeURIComponent(location)}&details=${encodeURIComponent(description)}&sf=true&output=xml`;
    const yahooLink = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodeURIComponent(title)}&st=${start}&et=${end}&desc=${encodeURIComponent(description)}&in_loc=${encodeURIComponent(location)}`;
    const outlookLink = `https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(title)}&startdt=${start}&enddt=${end}&location=${encodeURIComponent(location)}&body=${encodeURIComponent(description)}`;
    const office365Link = `https://outlook.office.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(title)}&startdt=${start}&enddt=${end}&location=${encodeURIComponent(location)}&body=${encodeURIComponent(description)}`;
    const appleLink = `https://calendar.apple.com/?event=${encodeURIComponent(title)}&start=${start}&end=${end}&location=${encodeURIComponent(location)}&notes=${encodeURIComponent(description)}`;

    return {
      google: googleLink,
      yahoo: yahooLink,
      outlook: outlookLink,
      office365: office365Link,
      apple: appleLink,
    };
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // Log the event data to the console
    console.log(eventData);

    // Generate calendar links
    const calendarLinks = generateCalendarLinks(eventData);

    // Add the event to the submitted events list along with the calendar links
    setSubmittedEvents([
      ...submittedEvents,
      { ...eventData, calendarLinks }
    ]);

    // Reset the form data after logging
    setEventData({
      title: '',
      description: '',
      location: '',
      startDate: '',
      endDate: '',
      timezone: 'UTC +05:00 Asia/Karachi',
      customLink: '',
      isOnline: false,
      isAllDay: false,
    });
  };

  return (
    <section className='calendeer-section'>
      <div className='container'>
        <form onSubmit={handleSubmit} className='calender-form'>
          <h2>Event Information</h2>
          <div>
            <label>Event Title</label>
            <input
              type="text"
              name="title"
              value={eventData.title}
              onChange={handleChange}
              placeholder="Event Title"
            />
          </div>
          <div>
            <label>Description</label>
            <textarea
              name="description"
              value={eventData.description}
              onChange={handleChange}
              placeholder="Event Description"
            ></textarea>
          </div>

          <div>
            <div className="labelcheckmark">
              <label htmlFor={eventData.isOnline ? "meeting" : "location"}>
                {eventData.isOnline ? "Meeting Link" : "Event Location"}
              </label>
              <div>
                <label htmlFor="online-event">Online Event?</label>
                <input
                  id="online-event"
                  type="checkbox"
                  name="isOnline"
                  checked={eventData.isOnline}
                  onChange={handleChange}
                />
              </div>
            </div>
            <input
              id={eventData.isOnline ? "meeting" : "location"}
              type="text"
              name={eventData.isOnline ? "meeting" : "location"}
              value={eventData.location}
              onChange={handleChange}
              placeholder={eventData.isOnline ? "Meeting Link" : "Event Location"}
            />
          </div>

          <div>
            <div className="labelcheckmark">
              <label htmlFor="startDate">Event Start Date</label>
              <div>
                <label htmlFor="all-day-event">All Day Event?</label>
                <input
                  id="all-day-event"
                  type="checkbox"
                  name="isAllDay"
                  checked={eventData.isAllDay}
                  onChange={handleChange}
                />
              </div>
            </div>

            {eventData.isAllDay ? (
              <input
                type="date"
                name="startDate"
                value={eventData.startDate}
                onChange={handleChange}
                className="custom-date-input"
                min={today} // Allow only today or future dates
              />
            ) : (
              <input
                type="datetime-local"
                name="startDate"
                value={eventData.startDate}
                onChange={handleChange}
                className="custom-date-input"
                min={today + "T00:00"} // For datetime-local, the format is 'YYYY-MM-DDTHH:MM'
              />
            )}
          </div>

          <div>
            <label htmlFor="endDate">Event End Date</label>
            {eventData.isAllDay ? (
              <input
                type="date"
                name="endDate"
                value={eventData.endDate}
                onChange={handleChange}
                className="custom-date-input"
                min={today} // Allow only today or future dates
              />
            ) : (
              <input
                type="datetime-local"
                name="endDate"
                value={eventData.endDate}
                onChange={handleChange}
                className="custom-date-input"
                min={today + "T00:00"} // For datetime-local, the format is 'YYYY-MM-DDTHH:MM'
              />
            )}
          </div>

          {!eventData.isAllDay && <TimeZone />}

          <button type="submit">Create Event Link</button>
        </form>

        {submittedEvents.map((event, index) => (
          <div className='calender-links'>
            <div className='calender-links-wrap'>
              <div>
                <h4>Direct Calendar Link</h4>
                <p>Direct link to a specific calendar directly</p>
              </div>
              <ul>
                <li><a href={event.calendarLinks.google} target="_blank" rel="noopener noreferrer">Google Calendar</a></li>
                <li><a href={event.calendarLinks.yahoo} target="_blank" rel="noopener noreferrer">Yahoo Calendar</a></li>
                <li><a href={event.calendarLinks.outlook} target="_blank" rel="noopener noreferrer">Outlook Calendar</a></li>
                <li><a href={event.calendarLinks.office365} target="_blank" rel="noopener noreferrer">Office 365 Calendar</a></li>
                <li><a href={event.calendarLinks.apple} target="_blank" rel="noopener noreferrer">Apple Calendar</a></li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EventForm;