import React, { useState } from 'react';
import fancyTextStyles from '../../components/FancyTextGeneratorToolComponents/fancyTextStyles';
import Modal from '../../components/FancyTextGeneratorToolComponents/DecorationModal';
import decorationItems from '../../components/FancyTextGeneratorToolComponents/DecorationData';

// Style
import '../../assets/FancyTextGeneratorToolAssets/App.css';

const FancyTextGenerator = () => {
    const [inputText, setInputText] = useState('');
    const [fancyTexts, setFancyTexts] = useState([]);
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFancyText, setSelectedFancyText] = useState('');

    const generateFancyTexts = (text) => {
        const newFancyTexts = fancyTextStyles.map(style => style(text));
        setFancyTexts(newFancyTexts);
        setCopiedIndex(null);
    };

    const handleChange = (e) => {
        const text = e.target.value;
        setInputText(text);
        if (text.trim() === '') {
            setFancyTexts([]);
        } else {
            generateFancyTexts(text);
        }
    };

    const copyToClipboard = (text, index) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedIndex(index);
            setTimeout(() => {
                setCopiedIndex(null);
            }, 1500);
        });
    };

    const handleDecorateClick = (text) => {
        setSelectedFancyText(text);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <section className='main_wrapper'>
            <div className='container'>
                <h2>Fancy Text Generator</h2>
                <input
                    type="text"
                    value={inputText}
                    onChange={handleChange}
                    placeholder="Enter your text"
                />
                <div>
                    {fancyTexts.map((text, index) => (
                        <div key={index} className='tranformed_text'>
                            <p style={{ margin: 0 }}>{text}</p>
                            <div className='buttons'>
                                <button onClick={() => copyToClipboard(text, index)}>
                                    {copiedIndex === index ? 'Copied' : 'Copy'}
                                </button>
                                <button onClick={() => handleDecorateClick(text)} disabled={!inputText.trim()}>
                                    Decorate
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Render Modal */}
            <Modal 
                isOpen={isModalOpen} 
                onClose={closeModal} 
                title="Decorated Text" 
                fancyText={selectedFancyText}
                items={decorationItems}
            />
        </section>
    );
};

export default FancyTextGenerator;