import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import StepOne from '../../components/PoliciesTermsToolComponensts/FormSteps/StepOne';
import StepTwo from '../../components/PoliciesTermsToolComponensts/FormSteps/StepTwo';
import StepThree from '../../components/PoliciesTermsToolComponensts/FormSteps/StepThree';
import StepFour from '../../components/PoliciesTermsToolComponensts/FormSteps/StepFour';

const FormPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  const [showState, setShowState] = useState(false);

  const stepCounts = {
    'privacy-policy': 4,
    'terms-and-conditions': 3,
    'eula': 3,
    'cookies-policy': 2,
    'disclaimer': 3,
    'return-and-refund-policy': 3,
  };

  const stepsCount = stepCounts[id] || 6;

  const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const selectedCountry = watch('country');
    setShowState(selectedCountry === 'United States');
  }, [watch]);

  const handleOptionClick = (option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : [...prevSelected, option]
    );
  };

  const handleNext = () => {
    if (activeStep < stepsCount - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      navigate(`/download/${id}`);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const onSubmit = (data) => {
    const formData = {
      ...data,
      id,
      selectedOptions,
    };
    console.log("data", formData);

    if (activeStep < stepsCount - 1) {
      handleNext();
    } else {
      navigate(`/download/${id}`, { state: formData });
    }
  };

  const handleUpgrade = () => {
    handleNext();
  };

  const handleSkip = () => {
    handleNext();
  };

  return (
    <div className="terms-generator">
      <div className='container'>
        <div className='form-inner'>
          <div className="stepper">
            {[...Array(stepsCount)].map((_, index) => (
              <div key={index} className={`step ${index <= activeStep ? 'active' : ''}`}>
                <span className="step-number">{index + 1}</span>
              </div>
            ))}
          </div>

          <div className='step-outer'>
            <div className="step-content">
              {activeStep === 0 && id && (
                id === 'cookies-policy' ? (
                  <StepTwo
                    id={id}
                    register={register}
                    watch={watch}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    isBusiness={isBusiness}
                    setIsBusiness={setIsBusiness}
                    showState={showState}
                  />
                ) : (
                  <StepOne
                    id={id}
                    selectedOptions={selectedOptions}
                    handleOptionClick={handleOptionClick}
                  />
                )
              )}
              {activeStep === 1 && id && (
                id === 'cookies-policy' ? (
                  <StepFour
                    id={id}
                    register={register}
                    activeStep={activeStep}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    watch={watch}
                  />
                ) : (
                  <StepTwo
                    id={id}
                    register={register}
                    watch={watch}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    isBusiness={isBusiness}
                    setIsBusiness={setIsBusiness}
                    showState={showState}
                  />
                )
              )}
              {activeStep === 2 && id && (
                id === 'privacy-policy' ? (
                  <StepThree
                    id={id}
                    register={register}
                  />
                ) : (id === 'terms-and-conditions' || id === 'eula' || id === 'disclaimer' || id === 'return-and-refund-policy') ? (
                  <StepFour
                    id={id}
                    register={register}
                    activeStep={activeStep}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    watch={watch}
                  />
                ) : null
              )}
              {activeStep === 3 && id && (
                <StepFour
                  id={id}
                  register={register}
                  activeStep={activeStep}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  watch={watch}
                />
              )}
            </div>

            <div className="navigation-buttons">
              {activeStep === stepsCount - 1 ? (
                <>
                  <button className='go-back' onClick={handleBack} disabled={activeStep === 0}>
                    Go Back
                  </button>
                  <button className='generate' onClick={handleSubmit(onSubmit)} disabled={activeStep === 0 && selectedOptions.length === 0}>
                    Generate
                    <FontAwesomeIcon icon={faDownload} />
                  </button>
                </>
              ) : (
                <>
                  <button className='prev-button' onClick={handleBack} disabled={activeStep === 0}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Previous Step
                  </button>
                  {/* <button className='next-button' onClick={handleSubmit(onSubmit)} disabled={activeStep === 0 && (selectedOptions.length === 0 || !isValid)}> */}
                  <button className='next-button' onClick={handleSubmit(onSubmit)}>
                    Next Step <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPage;