// dataItems.js
const decorationItems = [
    text => `彡★ ${text} 彡★`,
    text => `✨ ${text} ✨`,
    text => `🎉 ${text} 🎉`,
    text => `ஜ۩۞۩ஜ ${text} ஜ۩۞۩ஜ`,
    text => `彡★ ${text} 彡★`,
    text => `◦•●◉✿ ${text} ✿◉●•◦`,
    text => `▌│█║▌║▌║ ${text} ║▌║▌║█│▌`,
    text => `╰☆☆ ${text} ☆☆╮`,
    text => `╚»★«╝ ${text} ╚»★«╝`,
    text => `*•.¸♡ ${text} ♡¸.•*`,
    text => `↤↤↤↤↤ ${text} ↦↦↦↦↦`,
    text => `↫↫↫↫↫ ${text} ↬↬↬↬↬`,
    text => `▀▄▀▄▀▄ ${text} ▄▀▄▀▄▀`,
    text => `💙💜💛🧡❤️️ ${text} 💙💜💛🧡❤️️`,
    text => `💞💘💖 ${text} 💞💘💖`,
    text => `░▒▓█ ${text} █▓▒░`,
    text => `ıllıllı ${text} ıllıllı`,
    text => `【｡_｡】 ${text}【｡_｡】`,
    text => `(-_-) ${text} (-_-)`,
    text => `•´¯\`•. ${text} .•´¯\`•`,
    text => `.•♫•♬• ${text} •♬•♫•.`,
    text => `░▒▓█►─═ ${text} ═─◄█▓▒░`,
    text => `·.¸¸.·♩♪♫ ${text} ♫♪♩·.¸¸.·`,
    text => `🎀 ${text} 🎀`,
    text => `★·.·´¯\`·.·★ ${text} ★·.·´¯\`·.·★`,
    text => `➶➶➶➶➶ ${text} ➷➷➷➷➷`,
    text => `▁ ▂ ▄ ▅ ▆ ▇ █ ${text}  █ ▇ ▆ ▅ ▄ ▂ ▁`,
    text => `╰☆☆ ${text} ☆☆╮`,
    text => `🐙  ${text}  🐙`,
    text => `💔  ${text}  💔`,
    text => `💖 ${text} 💖`,
    text => `♦️ ${text} ♦️`,
    text => `▒▒▒▒▒ ${text} ▒▒▒▒▒`,
    text => `🌌  ${text}  🌌`,
    text => `⇝ ${text} ⇜`,
    text => `꧁ ${text} ꧂`,
    text => `☆ ${text} ☆`,
    text => `✿ ${text} ✿`,
    text => `🐻 ${text} 🐻`,
    text => `✿.｡.:* ☆:**:. ${text} .:**:.☆*.:｡.✿`,
    text => `ღ(¯\`◕‿◕´¯) ♫ ♪ ♫ ${text} ♫ ♪ ♫ (¯\`◕‿◕´¯)ღ`,
    text => `🎀 ${text} 🎀`,
    text => `🐺✌ ${text} 🐳🐧`,
    text => `🐟👽 ${text} ♢🎃`,
    text => `👽♤ ${text} 👺🎁`,
    text => `♝🐚 ${text} 🍧💔`,
    text => `🐰 ⋆ 🐢 🎀 ${text} 🎀 🐢 ⋆ 🐰`,
    text => `👤🎯 ${text} 🐚🎁`,
    text => `,-*' ^ '~*-.,_,.-*~ ${text} ~*-.,_,.-*~' ^ '*-,`,
];

export default decorationItems;