import React, { useState } from "react";
import moment from "moment-timezone";

const TimezoneDropdown = () => {
  const [selectedTimezone, setSelectedTimezone] = useState("");

  // Get all timezones and format them with UTC offset
  const timezones = moment.tz.names().map((timezone) => {
    const offset = moment.tz(timezone).utcOffset() / 60; // Offset in hours
    const formattedOffset = `UTC ${offset >= 0 ? "+" : ""}${offset}`;
    return {
      value: timezone,
      label: `${formattedOffset} ${timezone}`,
    };
  });

  return (
    <div>
      <label htmlFor="timezone">Select Timezone:</label>
      <select
        id="timezone"
        value={selectedTimezone}
        onChange={(e) => setSelectedTimezone(e.target.value)}
      >
        <option value="" disabled>
          Select your time zone
        </option>
        {timezones.map((tz) => (
          <option key={tz.value} value={tz.value}>
            {tz.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimezoneDropdown;