import React from 'react';
import { Routes, Route, useRoutes } from 'react-router-dom';

// Policies Terms Tool
import TermsFeedPage from './Pages/PoliciesTermsToolPages/TermsFeedPage';
import FormPage from './Pages/PoliciesTermsToolPages/FormPage';
import PoliciesPage from './Pages/PoliciesTermsToolPages/PoliciesPage';

// Fancy Text Generator Tool
import FancyTextGenerator from './Pages/FancyTextGeneratorToolPages/FancyTextGenerator';

// Calendar Share Link Tool
import CalendarShareLink from './Pages/CalendarShareLinkToolPages/CalendarShareLink';

// Voice Record Online Tool
import OnlineVoiceRecord from './Pages/OnlineVoiceRecordToolPages/OnlineVoiceRecord';
import Home from './Pages/Home';

const AppRoutes = ({ toolId }) => {
  return useRoutes([

    // Policies Terms Tool
    { path: '/policies-terms', element: <TermsFeedPage /> },
    { path: '/form/:id', element: <FormPage /> },
    { path: '/download/:id', element: <PoliciesPage /> },

    // Fancy Text Generator Tool
    { path: '/fancy-text', element: <FancyTextGenerator /> },

    // Calendar Share Link Tool
    { path: '/calendar-share', element: <CalendarShareLink /> },

    // Voice Record Online Tool
    { path: '/voice-recorder-online', element: <OnlineVoiceRecord /> },

    // { path: '/', element: <Home /> },

    { path: '*', element: <div>Select a valid tool.</div> },
  ]);
};

export default AppRoutes;