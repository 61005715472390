import React, { useState } from 'react';
import AppRoutes from './AppRoutes';
import './assets/App.css';

function App() {

  return (
    <AppRoutes />

  );
}

export default App;