
import React from 'react';
import { capitalizeWords } from '../utils';

const StepOne = ({ id, selectedOptions, handleOptionClick }) => {
  return (
    <>
      <div className="options">
        <h2>{capitalizeWords(id)} Generator</h2>
        <h3>Where will your {capitalizeWords(id)} be used?</h3>
        <p>Click all that apply</p>
        <div className={`option-card ${selectedOptions.includes("Website") ? 'active' : ''}`} onClick={() => handleOptionClick("Website")}>
          {id === 'eula' ? (
            <>
              <h4>Desktop app</h4>
              <p>EULA for a Desktop app</p>
            </>
          ) : id === 'disclaimer' ? (
            <>
              <h4>Website</h4>
              <p>This Disclaimer is for a Website</p>
            </>
          ) : (
            <>
              <h4>Website</h4>
              <p>{capitalizeWords(id)} for a Website</p>
            </>
          )}
        </div>

        <div
          className={`option-card ${selectedOptions.includes("App") ? 'active' : ''}`}
          onClick={() => handleOptionClick("App")}
        >
          {id === 'eula' ? (
            <>
              <h4>Mobile app</h4>
              <p>EULA for a Mobile app</p>
            </>
          ) : id === 'disclaimer' ? (
            <>
              <h4>App</h4>
              <p>This Disclaimer is for an App</p>
            </>
          ) : (
            <>
              <h4>App</h4>
              <p>{capitalizeWords(id)} for an App</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StepOne;