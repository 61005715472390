import React, { useEffect, useState } from 'react';

const Modal = ({ isOpen, onClose, title, fancyText, items }) => {
    const [decoratedText, setDecoratedText] = useState('');
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            setDecoratedText(fancyText); 
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen, fancyText]);

    const handleDecorate = (item) => {
        const newDecoratedText = item(fancyText);
        setDecoratedText(newDecoratedText);
        setCopied(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(decoratedText).then(() => {
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 1500);
        });
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close-icon" onClick={onClose}>
                    &times;
                </span>
                {decoratedText && (
                    <div className="text_decor">
                        <p>{decoratedText}</p>
                        <button onClick={copyToClipboard}>
                            {copied ? 'Copied' : 'Copy'}
                        </button>
                    </div>
                )}
                <div className="item-list">
                    {items.map((item, index) => {
                        const prefix = item('');
                        return (
                            <div key={index} className="item-box">
                                <button onClick={() => handleDecorate(item)}>
                                    {prefix.replace('${text}', '').trim()}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Modal;