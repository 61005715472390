import React, { useEffect, useState } from "react";
import { capitalizeWords } from '../utils';

const StepFour = ({ id = "defaultId", register, watch, handleSubmit, onSubmit, setValue }) => {
  const selectedMethods = watch("contactMethods") || [];
  const [contactDetails, setContactDetails] = useState({
    email: "",
    website: "",
    phone: "",
    mail: ""
  });

  const refundDays = [
    { label: "7 days", value: "7 days" },
    { label: "14 days", value: "14 days" },
    { label: "30 days", value: "30 days" },
    { label: "45 days", value: "45 days" },
  ];

  const contactFields = {
    "By email": {
      label: "What's the email?",
      placeholder: "office@mycompany.com",
      para: "e.g. office@mycompany.com",
      key: "email",
    },
    "By visiting a page on our website": {
      label: "What's the link?",
      placeholder: "http://www.mycompany.com/contact",
      para: "e.g. http://www.mycompany.com/contact",
      key: "website",
    },
    "By phone number": {
      label: "What's the phone number?",
      placeholder: "408.996.1010",
      para: "e.g. 408.996.1010",
      key: "phone",
    },
    "By sending post mail": {
      label: "What's the address?",
      placeholder: "767 Fifth Avenue New York, NY 10153, United States",
      para: "e.g. 767 Fifth Avenue New York, NY 10153, United States",
      key: "mail",
    },
  };

  const capitalizeTitle = (text) => capitalizeWords(text);

  useEffect(() => {
    const updatedDetails = {
      email: selectedMethods.includes("By email") ? contactDetails.email : "",
      website: selectedMethods.includes("By visiting a page on our website") ? contactDetails.website : "",
      phone: selectedMethods.includes("By phone number") ? contactDetails.phone : "",
      mail: selectedMethods.includes("By sending post mail") ? contactDetails.mail : ""
    };

    setContactDetails((prevDetails) => {
      if (JSON.stringify(prevDetails) !== JSON.stringify(updatedDetails)) {
        return updatedDetails;
      }
      return prevDetails;
    });
  }, [selectedMethods, contactDetails]);

  const handleInputChange = (methodKey, value) => {
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      [methodKey]: value
    }));
    setValue(`contactDetails.${methodKey}`, value);
  };

  return (
    <>
      {id === 'return-&-refund-policy' ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="radio-input-field">
              <h2>{capitalizeTitle(id)} Generator refund</h2>
              <h3>How many days customers have to ask for a refund?</h3>
              <p className="p-field">Please note that you need to select a minimum of 14 days for EU-based businesses.</p>
              <div className='radio-input'>
                {refundDays.map((type, index) => (
                  <label key={index} className="custom-radio">
                    <input
                      type="radio"
                      value={type.value}
                      {...register("refundDays", { required: true })}
                    />
                    <span className="radio-checkmark"></span>
                    {type.label}
                  </label>
                ))}
              </div>
            </div>

            <div className="options">
              <h3>What are the conditions that must be met to issue a refund?</h3>
              <p>Skip selecting an option if none apply to you</p>
              <div className="radio-input-field">
                <div className="radio-input">
                  <label className="custom-radio">
                    <input
                      type="checkbox"
                      value="Product must be returned in its original packaging"
                      {...register("conditionsOfReturn")}
                    />
                    <span className="radio-checkmark"></span>
                    Product must be returned in its original packaging
                  </label>

                  <label className="custom-radio">
                    <input
                      type="checkbox"
                      value="Product isn't used or damaged"
                      {...register("conditionsOfReturn")}
                    />
                    <span className="radio-checkmark"></span>
                    Product isn't used or damaged
                  </label>

                  <label className="custom-radio">
                    <input
                      type="checkbox"
                      value="Product must have the receipt or proof of purchase"
                      {...register("conditionsOfReturn")}
                    />
                    <span className="radio-checkmark"></span>
                    Product must have the receipt or proof of purchase
                  </label>
                </div>
              </div>
            </div>

            <div className="options">
              <h3>Do you issue refunds for items on sale (ie. discounted)?</h3>
              <div className="radio-input-field">
                <div className="radio-input">
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="Yes, items on sale can be refunded"
                      {...register("refundOfItems")}
                    />
                    <span className="radio-checkmark"></span>
                    Yes, items on sale can be refunded
                  </label>

                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="No, items on sale aren't eligible for refunds"
                      {...register("refundOfItems")}
                    />
                    <span className="radio-checkmark"></span>
                    No, items on sale aren't eligible for refunds
                  </label>
                </div>
              </div>
            </div>

            <div className="options">
              <h3>Can customers exchange a product with a new one?</h3>
              <div className="radio-input-field">
                <div className="radio-input">
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="Yes, customers can exchange the product with a new one by contacting us"
                      {...register("deviceInfoCollected")}
                    />
                    <span className="radio-checkmark"></span>
                    Yes, customers can exchange the product with a new one by contacting us
                  </label>

                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="No"
                      {...register("deviceInfoCollected")}
                    />
                    <span className="radio-checkmark"></span>
                    No
                  </label>
                  <h3>Where should customers send the product?</h3>
                  <input
                    type="text"
                    spellCheck="false"
                    className="text-area"
                    {...register("postalAddress")}
                  />
                  <p className="p-field">1 Infinite Loop<br />
                    Cupertino, CA 95014<br />
                    USA</p>
                </div>
              </div>
            </div>

            <div className="options">
              <h3>How can users contact you for any questions regarding your Privacy Policy?</h3>
              <p>Click all that apply</p>

              <div className="radio-input-field">
                <div className="radio-input">
                  {Object.keys(contactFields).map((method) => {
                    const { label, placeholder, para, key: methodKey } = contactFields[method];
                    return (
                      <div key={method} className="mid-check">
                        <label className="custom-radio">
                          <input
                            type="checkbox"
                            value={method}
                            {...register("contactMethods", { required: true })}
                            checked={selectedMethods.includes(method)}
                          />
                          <span className="radio-checkmark"></span>
                          {method}
                        </label>

                        {selectedMethods.includes(method) && (
                          <div className="business-details">
                            <div className="input-field border-style">
                              <label htmlFor={`${methodKey}-input`}>{label}</label>
                              <input
                                type="text"
                                id={`${methodKey}-input`}
                                placeholder={placeholder}
                                defaultValue={contactDetails[methodKey]}
                                onChange={(e) => handleInputChange(methodKey, e.target.value)}
                                {...register(`contactDetails.${methodKey}`)}
                              />
                              <p>{para}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="options">
              <h2>{capitalizeTitle(id)} Generator</h2>
              <h3>How can users contact you for any questions regarding your Privacy Policy?</h3>
              <p>Click all that apply</p>

              <div className="radio-input-field">
                <div className="radio-input">
                  {Object.keys(contactFields).map((method) => {
                    const { label, placeholder, para, key: methodKey } = contactFields[method];
                    return (
                      <div key={method} className="mid-check">
                        <label className="custom-radio">
                          <input
                            type="checkbox"
                            value={method}
                            {...register("contactMethods", { required: true })}
                            checked={selectedMethods.includes(method)}
                          />
                          <span className="radio-checkmark"></span>
                          {method}
                        </label>

                        {selectedMethods.includes(method) && (
                          <div className="business-details">
                            <div className="input-field border-style">
                              <label htmlFor={`${methodKey}-input`}>{label}</label>
                              <input
                                type="text"
                                id={`${methodKey}-input`}
                                placeholder={placeholder}
                                defaultValue={contactDetails[methodKey]}
                                onChange={(e) => handleInputChange(methodKey, e.target.value)}
                                {...register(`contactDetails.${methodKey}`)}
                              />
                              <p>{para}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </form>
        </>
      )
      }
    </>
  );
};

export default StepFour;