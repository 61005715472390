import React from 'react';

const Privacy = ({ id, date, formData, personalInfoCollected, contactDetails, contactMethods, selectedOptions, conditionsOfReturn, }) => {

  return (
    <>
      {id === 'Privacy Policy' ? (
        <>
          <h1>{id}</h1>
          <p>Last updated: {date}</p>
          <p>This {id} describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
          <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this {id}. This {id} has been created with the help of the <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank">{id} Generator</a>.</p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h3>Definitions</h3>
          <p>For the purposes of this {id}:</p>
          <ul>
            <li>
              <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
            </li>
            <li>
              <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
            </li>
            {selectedOptions && selectedOptions.includes('App') && (
              <li>
                <p><strong>Application</strong> refers to {formData.appName}, the software program provided by the Company.</p>
              </li>
            )}
            <li>
              <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to {formData.appName}.</p>
            </li>
            <li>
              <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
            </li>
            <li>
              <p><strong>Country</strong> refers to: {formData.state}, {formData.country}</p>
            </li>
            <li>
              <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
            </li>
            <li>
              <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
            </li>
            <li>
              <p><strong>Service</strong> refers to the Application or the Website or both.</p>
            </li>
            <li>
              <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
            </li>
            {personalInfoCollected.includes("Social Media Profile information (ie. from Connect with Facebook, Sign In With Twitter)") && (
              <li>
                <p><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
              </li>
            )}
            <li>
              <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
            </li>
            {selectedOptions && selectedOptions.includes('Website') && (
              <li>
                <p><strong>Website</strong> refers to {formData.appName}, accessible from <a href={formData.websiteURL} rel="external nofollow noopener" target="_blank">${formData.websiteURL}</a></p>
              </li>
            )}
            <li>
              <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
          </ul>
          <h2>Collecting and Using Your Personal Data</h2>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
          <ul>
            {personalInfoCollected
              .filter(
                (info) =>
                  info !== "Social Media Profile information (ie. from Connect with Facebook, Sign In With Twitter)" &&
                  info !== "Others"
              )
              .map((info, index) => (
                <li key={index}>
                  <p>{info}</p>
                </li>
              ))}
            <li>
              <p>Usage Data</p>
            </li>
          </ul>
          <h4>Usage Data</h4>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
          <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
          <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
          {personalInfoCollected.includes("Social Media Profile information (ie. from Connect with Facebook, Sign In With Twitter)") && (
            <>
              <h4>Information from Third-Party Social Media Services</h4>
              <p>The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</p>
              <ul>
                <li>Google</li>
                <li>Facebook</li>
                <li>Instagram</li>
                <li>Twitter</li>
                <li>LinkedIn</li>
              </ul>
              <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.</p>
              <p>You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this {id}.</p>
            </>
          )}
          <h4>Information Collected while Using the Application</h4>
          <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
          <ul>
            <li>Information regarding your location</li>
            <li>Information from your Device's phone book (contacts list)</li>
            <li>Pictures and other information from your Device's camera and photo library</li>
          </ul>
          <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
          <p>You can enable or disable access to this information at any time, through Your Device settings.</p>
          <h4>Tracking Technologies and Cookies</h4>
          <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
          <ul>
            <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
            <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
          </ul>
          <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies on <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank">TermsFeed website</a> article.</p>
          <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
          <ul>
            <li>
              <p><strong>Necessary / Essential Cookies</strong></p>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
            </li>
            <li>
              <p><strong>{id} / Notice Acceptance Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
            </li>
            <li>
              <p><strong>Functionality Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
            </li>
          </ul>
          <p>For more information about the cookies we use and your choices regarding cookies, please visit our {id} or the Cookies section of our {id}.</p>
          <h3>Use of Your Personal Data</h3>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
            </li>
            <li>
              <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
            </li>
            <li>
              <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
            </li>
            <li>
              <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
            </li>
            <li>
              <p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
            </li>
            <li>
              <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
            </li>
            <li>
              <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
            </li>
            <li>
              <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
            </li>
          </ul>
          <p>We may share Your personal information in the following situations:</p>
          <ul>
            <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
            <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
            <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this {id}. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
            <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
            <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
            <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
          </ul>
          <h3>Retention of Your Personal Data</h3>
          <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this {id}. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
          <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
          <h3>Transfer of Your Personal Data</h3>
          <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
          <p>Your consent to this {id} followed by Your submission of such information represents Your agreement to that transfer.</p>
          <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this {id} and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
          <h3>Delete Your Personal Data</h3>
          <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
          <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
          <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
          <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
          <h3>Disclosure of Your Personal Data</h3>
          <h4>Business Transactions</h4>
          <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different {id}.</p>
          <h4>Law enforcement</h4>
          <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
          <h4>Other legal requirements</h4>
          <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>
          <h3>Security of Your Personal Data</h3>
          <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
          <h2>Children's Privacy</h2>
          <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
          <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
          <h2>Links to Other Websites</h2>
          <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the {id} of every site You visit.</p>
          <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
          <h2>Changes to this {id}</h2>
          <p>We may update Our {id} from time to time. We will notify You of any changes by posting the new {id} on this page.</p>
          <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this {id}.</p>
          <p>You are advised to review this {id} periodically for any changes. Changes to this {id} are effective when they are posted on this page.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about this {id}, You can contact us:</p>
          <ul>
            {contactMethods.map((method, index) => {
              if (method === "By email" && contactDetails.email) {
                return (
                  <li key={index}>
                    <p>By email: {contactDetails.email}</p>
                  </li>
                );
              } else if (method === "By visiting a page on our website" && contactDetails.website) {
                return (
                  <li key={index}>
                    <p>
                      By visiting this page on our website:
                      <a href={contactDetails.website} rel="external nofollow noopener" target="_blank">
                        {contactDetails.website}
                      </a>
                    </p>
                  </li>
                );
              } else if (method === "By phone number" && contactDetails.phone) {
                return (
                  <li key={index}>
                    <p>By phone number: {contactDetails.phone}</p>
                  </li>
                );
              } else if (method === "By sending post mail" && contactDetails.mail) {
                return (
                  <li key={index}>
                    <p>By mail: {contactDetails.mail}</p>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </>
      ) : id === 'Terms & Conditions' ? (
        <>
          <h1>{id}</h1>
          <p>Last updated:  {date}</p>
          <p>Please read these {id} carefully before using Our Service.</p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h3>Definitions</h3>
          <p>For the purposes of these {id}:</p>
          <ul>
            {selectedOptions && selectedOptions.includes('App') && (
              <>
                <li>
                  <p><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named Malik Daniel</p>
                </li>
                <li>
                  <p><strong>Application Store</strong> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.</p>
                </li>
              </>
            )}
            <li>
              <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
            </li>
            <li>
              <p><strong>Country</strong> refers to: {formData.state}, {formData.country}</p>
            </li>
            <li>
              <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to {formData.appName}.</p>
            </li>
            <li>
              <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
            </li>
            <li>
              <p><strong>Service</strong> refers to the Application or the Website or both.</p>
            </li>
            <li>
              <p><strong>{id}</strong> (also referred as &quot;Terms&quot;) mean these {id} that form the entire agreement between You and the Company regarding the use of the Service. This {id} agreement has been created with the help of the <a href="https://www.termsfeed.com/terms-and-conditions-generator/" target="_blank">{id} Generator</a>.</p>
            </li>
            <li>
              <p><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>
            </li>
            {selectedOptions && selectedOptions.includes('Website') && (
              <li>
                <p><strong>Website</strong> refers to {formData.appName}, accessible from <a href={formData.websiteURL} rel="external nofollow noopener" target="_blank">${formData.websiteURL}</a></p>
              </li>
            )}
            <li>
              <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
          </ul>
          <h2>Acknowledgment</h2>
          <p>These are the {id} governing the use of this Service and the agreement that operates between You and the Company. These {id} set out the rights and obligations of all users regarding the use of the Service.</p>
          <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these {id}. These {id} apply to all visitors, users and others who access or use the Service.</p>
          <p>By accessing or using the Service You agree to be bound by these {id}. If You disagree with any part of these {id} then You may not access the Service.</p>
          <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
          <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
          <h2>Links to Other Websites</h2>
          <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
          <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
          <p>We strongly advise You to read the {id} and privacy policies of any third-party web sites or services that You visit.</p>
          <h2>Termination</h2>
          <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these {id}.</p>
          <p>Upon termination, Your right to use the Service will cease immediately.</p>
          <h2>Limitation of Liability</h2>
          <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
          <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
          <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
          <h2>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; {id}</h2>
          <p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
          <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
          <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
          <h2>Governing Law</h2>
          <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
          <h2>Disputes Resolution</h2>
          <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
          <h2>For European Union (EU) Users</h2>
          <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which You are resident.</p>
          <h2>United States Legal Compliance</h2>
          <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a &quot;terrorist supporting&quot; country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
          <h2>Severability and Waiver</h2>
          <h3>Severability</h3>
          <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
          <h3>Waiver</h3>
          <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>
          <h2>Translation Interpretation</h2>
          <p>These {id} may have been translated if We have made them available to You on our Service.
            You agree that the original English text shall prevail in the case of a dispute.</p>
          <h2>Changes to These {id}</h2>
          <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
          <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about these {id}, You can contact us:</p>
          <ul>
            {contactMethods.map((method, index) => {
              if (method === "By email" && contactDetails.email) {
                return (
                  <li key={index}>
                    <p>By email: {contactDetails.email}</p>
                  </li>
                );
              } else if (method === "By visiting a page on our website" && contactDetails.website) {
                return (
                  <li key={index}>
                    <p>
                      By visiting this page on our website:
                      <a href={contactDetails.website} rel="external nofollow noopener" target="_blank">
                        {contactDetails.website}
                      </a>
                    </p>
                  </li>
                );
              } else if (method === "By phone number" && contactDetails.phone) {
                return (
                  <li key={index}>
                    <p>By phone number: {contactDetails.phone}</p>
                  </li>
                );
              } else if (method === "By sending post mail" && contactDetails.mail) {
                return (
                  <li key={index}>
                    <p>By mail: {contactDetails.mail}</p>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </>
      ) : id === 'Eula' ? (
        <>
          <h1>End-User License Agreement (&quot;Agreement&quot;)</h1>
          <p>Last updated: {date}</p>
          <p>Please read this End-User License Agreement carefully before clicking the &quot;I Agree&quot; button, downloading or using {formData.appName}.</p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h3>Definitions</h3>
          <p>For the purposes of this End-User License Agreement:</p>
          <ul>
            <li>
              <p><strong>Agreement</strong> means this End-User License Agreement that forms the entire agreement between You and the Company regarding the use of the Application. This Agreement has been created with the help of the <a href="https://www.termsfeed.com/eula-generator/" target="_blank">{id} Generator</a>.</p>
            </li>
            {selectedOptions && selectedOptions.includes('App') && (
              `<li>
              <p><strong>Application</strong> means the software program provided by the Company downloaded by You to a Device, named {formData.appName}</p>
            </li>`
            )}
            <li>
              <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to {formData.appName}.</p>
            </li>
            <li>
              <p><strong>Content</strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</p>
            </li>
            <li>
              <p><strong>Country</strong> refers to: {formData.state} {formData.country}</p>
            </li>
            <li>
              <p><strong>Device</strong> means any device that can access the Application such as a computer, a cellphone or a digital tablet.</p>
            </li>
            <li>
              <p><strong>Third-Party Services</strong> means any services or content (including data, information, applications and other products services) provided by a third-party that may be displayed, included or made available by the Application.</p>
            </li>
            <li>
              <p><strong>You</strong> means the individual accessing or using the Application or the company, or other legal entity on behalf of which such individual is accessing or using the Application, as applicable.</p>
            </li>
          </ul>
          <h2>Acknowledgment</h2>
          <p>By clicking the &quot;I Agree&quot; button, downloading or using the Application, You are agreeing to be bound by the terms and conditions of this Agreement. If You do not agree to the terms of this Agreement, do not click on the &quot;I Agree&quot; button, do not download or do not use the Application.</p>
          <p>This Agreement is a legal document between You and the Company and it governs your use of the Application made available to You by the Company.</p>
          <p>The Application is licensed, not sold, to You by the Company for use strictly in accordance with the terms of this Agreement.</p>
          <h2>License</h2>
          <h3>Scope of License</h3>
          <p>The Company grants You a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application strictly in accordance with the terms of this Agreement.</p>
          <p>The license that is granted to You by the Company is solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.</p>
          <h2>Third-Party Services</h2>
          <p>The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services.</p>
          <p>You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services.</p>
          <p>You must comply with applicable Third parties' Terms of agreement when using the Application. Third-party Services and links thereto are provided solely as a convenience to You and You access and use them entirely at your own risk and subject to such third parties' Terms and conditions.</p>
          <h2>Term and Termination</h2>
          <p>This Agreement shall remain in effect until terminated by You or the Company.
            The Company may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
          <p>This Agreement will terminate immediately, without prior notice from the Company, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your Device or from your computer.</p>
          <p>Upon termination of this Agreement, You shall cease all use of the Application and delete all copies of the Application from your Device.</p>
          <p>Termination of this Agreement will not limit any of the Company's rights or remedies at law or in equity in case of breach by You (during the term of this Agreement) of any of your obligations under the present Agreement.</p>
          <h2>Indemnification</h2>
          <p>You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</p>
          <h2>No Warranties</h2>
          <p>The Application is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
          <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
          <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law. To the extent any warranty exists under law that cannot be disclaimed, the Company shall be solely responsible for such warranty.</p>
          <h2>Limitation of Liability</h2>
          <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You for the Application or through the Application or 100 USD if You haven't purchased anything through the Application.</p>
          <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
          <p>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to You.</p>
          <h2>Severability and Waiver</h2>
          <h3>Severability</h3>
          <p>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
          <h3>Waiver</h3>
          <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>
          <h2>Product Claims</h2>
          <p>The Company does not make any warranties concerning the Application.</p>
          <h2>United States Legal Compliance</h2>
          <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a &quot;terrorist supporting&quot; country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
          <h2>Changes to this Agreement</h2>
          <p>The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at the sole discretion of the Company.</p>
          <p>By continuing to access or use the Application after any revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Application.</p>
          <h2>Governing Law</h2>
          <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
          <h2>Entire Agreement</h2>
          <p>The Agreement constitutes the entire agreement between You and the Company regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between You and the Company.</p>
          <p>You may be subject to additional terms and conditions that apply when You use or purchase other Company's services, which the Company will provide to You at the time of such use or purchase.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about this Agreement, You can contact Us:</p>
          <ul>
            {contactMethods.map((method, index) => {
              if (method === "By email" && contactDetails.email) {
                return (
                  <li key={index}>
                    <p>By email: {contactDetails.email}</p>
                  </li>
                );
              } else if (method === "By visiting a page on our website" && contactDetails.website) {
                return (
                  <li key={index}>
                    <p>
                      By visiting this page on our website:
                      <a href={contactDetails.website} rel="external nofollow noopener" target="_blank">
                        {contactDetails.website}
                      </a>
                    </p>
                  </li>
                );
              } else if (method === "By phone number" && contactDetails.phone) {
                return (
                  <li key={index}>
                    <p>By phone number: {contactDetails.phone}</p>
                  </li>
                );
              } else if (method === "By sending post mail" && contactDetails.mail) {
                return (
                  <li key={index}>
                    <p>By mail: {contactDetails.mail}</p>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </>
      ) : id === 'Cookies Policy' ? (
        <>
          <h1>{id}</h1>
          <p>Last updated: {date}</p>
          <p>This {id} explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used. This {id} has been created with the help of the <a href="https://www.termsfeed.com/cookies-policy-generator/" target="_blank">{id} Generator</a>.</p>
          <p>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.</p>
          <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.</p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h3>Definitions</h3>
          <p>For the purposes of this {id}:</p>
          <ul>
            <li><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this {id}) refers to {formData.websiteName}.</li>
            <li><strong>Cookies</strong> means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.</li>
            <li><strong>Website</strong> refers to {formData.websiteName}, accessible from <a href="https://www.gimyduvezir.net" rel="external nofollow noopener" target="_blank">https://www.gimyduvezir.net</a></li>
            <li><strong>You</strong> means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.</li>
          </ul>
          <h2>The use of the Cookies</h2>
          <h3>Type of Cookies We Use</h3>
          <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.</p>
          <p>We use both session and persistent Cookies for the purposes set out below:</p>
          <ul>
            <li>
              <p><strong>Necessary / Essential Cookies</strong></p>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
            </li>
            <li>
              <p><strong>Functionality Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
            </li>
          </ul>
          <h3>Your Choices Regarding Cookies</h3>
          <p>If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.</p>
          <p>If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.</p>
          <p>If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.</p>
          <ul>
            <li>
              <p>For the Chrome web browser, please visit this page from Google: <a href="https://support.google.com/accounts/answer/32050" rel="external nofollow noopener" target="_blank">https://support.google.com/accounts/answer/32050</a></p>
            </li>
            <li>
              <p>For the Internet Explorer web browser, please visit this page from Microsoft: <a href="http://support.microsoft.com/kb/278835" rel="external nofollow noopener" target="_blank">http://support.microsoft.com/kb/278835</a></p>
            </li>
            <li>
              <p>For the Firefox web browser, please visit this page from Mozilla: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" rel="external nofollow noopener" target="_blank">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></p>
            </li>
            <li>
              <p>For the Safari web browser, please visit this page from Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" rel="external nofollow noopener" target="_blank">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></p>
            </li>
          </ul>
          <p>For any other web browser, please visit your web browser's official web pages.</p>
          <h3>More Information about Cookies</h3>
          <p>You can learn more about cookies here: <a href="https://www.termsfeed.com/blog/cookies/" target="_blank">All About Cookies by TermsFeed</a>.</p>
          <h3>Contact Us</h3>
          <p>If you have any questions about this {id}, You can contact us:</p>
          <ul>
            {contactMethods.map((method, index) => {
              if (method === "By email" && contactDetails.email) {
                return (
                  <li key={index}>
                    <p>By email: {contactDetails.email}</p>
                  </li>
                );
              } else if (method === "By visiting a page on our website" && contactDetails.website) {
                return (
                  <li key={index}>
                    <p>
                      By visiting this page on our website:
                      <a href={contactDetails.website} rel="external nofollow noopener" target="_blank">
                        {contactDetails.website}
                      </a>
                    </p>
                  </li>
                );
              } else if (method === "By phone number" && contactDetails.phone) {
                return (
                  <li key={index}>
                    <p>By phone number: {contactDetails.phone}</p>
                  </li>
                );
              } else if (method === "By sending post mail" && contactDetails.mail) {
                return (
                  <li key={index}>
                    <p>By mail: {contactDetails.mail}</p>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </>
      ) : id === 'Disclaimer' ? (
        <>
          <h1>{id}</h1>
          <p>Last updated: {date}</p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h3>Definitions</h3>
          <p>For the purposes of this {id}:</p>
          <ul>
            <li><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this {id}) refers to {formData.websiteName}.</li>
            <li><strong>Service</strong> refers to the Website or the Application or both.</li>
            <li><strong>You</strong> means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            <li><strong>Website</strong> refers to {formData.websiteName}, accessible from <a href={formData.websiteURL} rel="external nofollow noopener" target="_blank">{formData.websiteURL}</a></li>
            <li><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device named Noelle Contreras.</li>
          </ul>
          <h2>{id}</h2>
          <p>The information contained on the Service is for general information purposes only.</p>
          <p>The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
          <p>In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice. This {id} has been created with the help of the <a href="https://www.termsfeed.com/{id}-generator/" target="_blank">{id} Generator</a>.</p>
          <p>The Company does not warrant that the Service is free of viruses or other harmful components.</p>
          <h2>External Links {id}</h2>
          <p>The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.</p>
          <p>Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>
          <h2>Errors and Omissions {id}</h2>
          <p>The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to ensure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.</p>
          <p>The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.</p>
          <h2>Fair Use {id}</h2>
          <p>The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making such material available for criticism, comment, news reporting, teaching, scholarship, or research.</p>
          <p>The Company believes this constitutes a &quot;fair use&quot; of any such copyrighted material as provided for in section 107 of the United States Copyright law.</p>
          <p>If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner.</p>
          <h2>Views Expressed {id}</h2>
          <p>The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.</p>
          <p>Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserves the right to delete any comment for any reason whatsoever.</p>
          <h2>No Responsibility {id}</h2>
          <p>The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>
          <p>In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.</p>
          <h2>&quot;Use at Your Own Risk&quot; {id}</h2>
          <p>All information in the Service is provided &quot;as is&quot;, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.</p>
          <p>The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about this {id}, You can contact Us:</p>
          <ul>
            {contactMethods.map((method, index) => {
              if (method === "By email" && contactDetails.email) {
                return (
                  <li key={index}>
                    <p>By email: {contactDetails.email}</p>
                  </li>
                );
              } else if (method === "By visiting a page on our website" && contactDetails.website) {
                return (
                  <li key={index}>
                    <p>
                      By visiting this page on our website:
                      <a href={contactDetails.website} rel="external nofollow noopener" target="_blank">
                        {contactDetails.website}
                      </a>
                    </p>
                  </li>
                );
              } else if (method === "By phone number" && contactDetails.phone) {
                return (
                  <li key={index}>
                    <p>By phone number: {contactDetails.phone}</p>
                  </li>
                );
              } else if (method === "By sending post mail" && contactDetails.mail) {
                return (
                  <li key={index}>
                    <p>By mail: {contactDetails.mail}</p>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </>
      ) : id === 'Return And Refund Policy' ? (
        <>
          <h1>Return and Refund Policy</h1>
          <p>Last updated: {date}</p>
          <p>
            Thank you for shopping at {formData.websiteName} website{formData.appName ? ` and ${formData.appName} app` : ''}.
          </p>
          <p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns. This Return and Refund Policy has been created with the help of the <a href="https://www.termsfeed.com/return-and-refund-policy-generator/" target="_blank">Return and Refund Policy Generator</a>.</p>
          <p>The following terms are applicable for any products that You purchased with Us.</p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h3>Definitions</h3>
          <p>For the purposes of this Return and Refund Policy:</p>
          <ul>
            {selectedOptions && selectedOptions.includes('App') && (
              <li>
                <p><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named {formData.appName}</p>
              </li>
            )}
            <li>
              <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to {formData.websiteName}.</p>
            </li>
            <li>
              <p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
            </li>
            <li>
              <p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
            </li>
            <li>
              <p><strong>Service</strong> refers to the Application or the Website or both.</p>
            </li>
            {selectedOptions && selectedOptions.includes('Website') && (
              <li>
                <p><strong>Website</strong> refers to {formData.websiteName}, accessible from <a href={formData.websiteURL} rel="external nofollow noopener" target="_blank">{formData.websiteURL}</a></p>
              </li>
            )}
            <li>
              <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
          </ul>
          <h2>Your Order Cancellation Rights</h2>
          <p>You are entitled to cancel Your Order within {formData.refundDays} without giving any reason for doing so.</p>
          <p>The deadline for cancelling an Order is {formData.refundDays} from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>
          <p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
          <ul>
            {contactMethods.map((method, index) => {
              if (method === "By email" && contactDetails.email) {
                return (
                  <li key={index}>
                    <p>By email: {contactDetails.email}</p>
                  </li>
                );
              } else if (method === "By visiting a page on our website" && contactDetails.website) {
                return (
                  <li key={index}>
                    <p>
                      By visiting this page on our website:
                      <a href={contactDetails.website} rel="external nofollow noopener" target="_blank">
                        {contactDetails.website}
                      </a>
                    </p>
                  </li>
                );
              } else if (method === "By phone number" && contactDetails.phone) {
                return (
                  <li key={index}>
                    <p>By phone number: {contactDetails.phone}</p>
                  </li>
                );
              } else if (method === "By sending post mail" && contactDetails.mail) {
                return (
                  <li key={index}>
                    <p>By mail: {contactDetails.mail}</p>
                  </li>
                );
              }
              return null;
            })}
          </ul>
          <p>We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
          <h2>Conditions for Returns</h2>
          <p>In order for the Goods to be eligible for a return, please make sure that:</p>
          <ul>
            <li>The Goods were purchased in the last {formData.refundDays}</li>
            <li>The Goods are in the original packaging</li>
          </ul>
          <p>The following Goods cannot be returned:</p>
          <ul>
            <li>The supply of Goods made to Your specifications or clearly personalized.</li>
            <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
            <li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
            <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
          </ul>
          <p>We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
          <p>Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.</p>
          <h2>Returning Goods</h2>
          <p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
          <p>{formData.postalAddress}</p>
          <p>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>
          <h2>Gifts</h2>
          <p>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.</p>
          <p>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.</p>
          <h3>Contact Us</h3>
          <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
          <ul>
            {contactMethods.map((method, index) => {
              if (method === "By email" && contactDetails.email) {
                return (
                  <li key={index}>
                    <p>By email: {contactDetails.email}</p>
                  </li>
                );
              } else if (method === "By visiting a page on our website" && contactDetails.website) {
                return (
                  <li key={index}>
                    <p>
                      By visiting this page on our website:
                      <a href={contactDetails.website} rel="external nofollow noopener" target="_blank">
                        {contactDetails.website}
                      </a>
                    </p>
                  </li>
                );
              } else if (method === "By phone number" && contactDetails.phone) {
                return (
                  <li key={index}>
                    <p>By phone number: {contactDetails.phone}</p>
                  </li>
                );
              } else if (method === "By sending post mail" && contactDetails.mail) {
                return (
                  <li key={index}>
                    <p>By mail: {contactDetails.mail}</p>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </>
      )
        : null}
    </>
  )
}
export default Privacy;