import React from 'react';
import { capitalizeWords } from '../utils';

const StepThree = ({ id = '', register, errors }) => {
  return (
    <>
      <div className="options">
        <h2>{capitalizeWords(id)} Generator</h2>
        <h3>What kind of personal information do you collect from users?</h3>
        <p>Click all that apply</p>
        
        <div className="radio-input-field">
          <div className="radio-input">
            <label className="custom-radio">
              <input
                type="checkbox"
                value="Email Address"
                {...register("personalInfoCollected", { required: true })}
              />
              <span className="radio-checkmark"></span>
              Email Address
            </label>

            <label className="custom-radio">
              <input
                type="checkbox"
                value="First Name and Last Name"
                {...register("personalInfoCollected", { required: true })}
              />
              <span className="radio-checkmark"></span>
              First Name and Last Name
            </label>

            <label className="custom-radio">
              <input
                type="checkbox"
                value="Phone number"
                {...register("personalInfoCollected", { required: true })}
              />
              <span className="radio-checkmark"></span>
              Phone number
            </label>

            <label className="custom-radio">
              <input
                type="checkbox"
                value="Address, State, Province, ZIP/Postal code, City"
                {...register("personalInfoCollected", { required: true })}
              />
              <span className="radio-checkmark"></span>
              Address, State, Province, ZIP/Postal code, City
            </label>

            <label className="custom-radio">
              <input
                type="checkbox"
                value="Social Media Profile information (ie. from Connect with Facebook, Sign In With Twitter)"
                {...register("personalInfoCollected", { required: true })}
              />
              <span className="radio-checkmark"></span>
              Social Media Profile information (ie. from Connect with Facebook, Sign In With Twitter)
            </label>

            <label className="custom-radio">
              <input
                type="checkbox"
                value="Others"
                {...register("personalInfoCollected", { required: true })}
              />
              <span className="radio-checkmark"></span>
              Others
            </label>
          </div>
        </div>
      </div>

      <div className="options">
        <h3>Select additional information you collect from a device (mobile/tablet)</h3>
        <p>Skip selecting an option if none apply to you</p>
        <div className="radio-input-field">
          <div className="radio-input">
            <label className="custom-radio">
              <input
                type="checkbox"
                value="Location (GPS)"
                {...register("deviceInfoCollected")}
              />
              <span className="radio-checkmark"></span>
              Location (GPS)
            </label>

            <label className="custom-radio">
              <input
                type="checkbox"
                value="Phonebook (Contacts list)"
                {...register("deviceInfoCollected")}
              />
              <span className="radio-checkmark"></span>
              Phonebook (Contacts list)
            </label>

            <label className="custom-radio">
              <input
                type="checkbox"
                value="Camera (Pictures)"
                {...register("deviceInfoCollected")}
              />
              <span className="radio-checkmark"></span>
              Camera (Pictures)
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepThree;