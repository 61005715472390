const fancyTextStyles = [
    text => text.toUpperCase(),
    text => text.split('').join(' '),
    text => text.split('').join('♥'),
    text => text.split('').join('░'),
    text => text.split('').join('𝕕'),
    text => text.split('').join('≋'),
    text => text.replace(/./g, char => `『${char}』`),
    text => text.replace(/./g, char => `【${char}】`),
    text => {
        const fancyChars = {
            'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝒻',
            'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿',
            'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜',
            'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉',
            'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏',
            'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔',
            'F': '𝓕', 'G': '𝓖', 'H': '𝓗', 'I': '𝓘', 'J': '𝓙',
            'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝', 'O': '𝓞',
            'P': '𝓟', 'Q': '𝓠', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣',
            'U': '𝓤', 'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩',
        };

        const transformedText = text.replace(/./g, char => fancyChars[char] || char);

        return transformedText;
    },
    text => {
        const greekChars = {
            'a': 'α', 'b': 'β', 'c': 'γ', 'd': 'δ', 'e': 'ε',
            'f': 'ϕ', 'g': 'γ', 'h': 'η', 'i': 'ι', 'j': 'ϳ',
            'k': 'κ', 'l': 'λ', 'm': 'μ', 'n': 'ν', 'o': 'ο',
            'p': 'π', 'q': 'θ', 'r': 'ρ', 's': 'σ', 't': 'τ',
            'u': 'υ', 'v': 'ϖ', 'w': 'ω', 'x': 'χ', 'y': 'υ', 'z': 'ζ',
            'A': 'Α', 'B': 'Β', 'C': 'Γ', 'D': 'Δ', 'E': 'Ε',
            'F': 'Φ', 'G': 'Γ', 'H': 'Η', 'I': 'Ι', 'J': 'ϙ',
            'K': 'Κ', 'L': 'Λ', 'M': 'Μ', 'N': 'Ν', 'O': 'Ο',
            'P': 'Π', 'Q': 'Θ', 'R': 'Ρ', 'S': 'Σ', 'T': 'Τ',
            'U': 'Υ', 'V': 'ϖ', 'W': 'Ω', 'X': 'Χ', 'Y': 'Υ', 'Z': 'Ζ',
        };

        const transformedText = text.replace(/./g, char => greekChars[char] || char);

        return transformedText;
    },
    text => {
        const cyrillicChars = {
            'a': 'а', 'b': 'б', 'c': 'ц', 'd': 'д', 'e': 'е',
            'f': 'ф', 'g': 'г', 'h': 'х', 'i': 'и', 'j': 'й',
            'k': 'к', 'l': 'л', 'm': 'м', 'n': 'н', 'o': 'о',
            'p': 'п', 'q': 'я', 'r': 'р', 's': 'с', 't': 'т',
            'u': 'у', 'v': 'в', 'w': 'ш', 'x': 'х', 'y': 'ы', 'z': 'з',
            'A': 'А', 'B': 'Б', 'C': 'Ц', 'D': 'Д', 'E': 'Е',
            'F': 'Ф', 'G': 'Г', 'H': 'Х', 'I': 'И', 'J': 'Й',
            'K': 'К', 'L': 'Л', 'M': 'М', 'N': 'Н', 'O': 'О',
            'P': 'П', 'Q': 'Я', 'R': 'Р', 'S': 'С', 'T': 'Т',
            'U': 'У', 'V': 'В', 'W': 'Ш', 'X': 'Х', 'Y': 'Ы', 'Z': 'З',
        };

        const transformedText = text.replace(/./g, char => cyrillicChars[char] || char);

        return transformedText;
    },
    text => {
        const styleChars2 = {
            'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝒻',
            'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿',
            'k': '𝓀', 'l': '𝕀', 'm': '𝓂', 'n': 'ᶰ', 'o': '𝑜',
            'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉',
            'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏',
            'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔',
            'F': '𝓕', 'G': '𝓖', 'H': '𝓗', 'I': '𝕀', 'J': '𝓙',
            'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝', 'O': '𝓞',
            'P': '𝓟', 'Q': '𝓠', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣',
            'U': '𝓤', 'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩',
            'é': 'é'
        };

        const transformedText2 = text.replace(/./g, char => styleChars2[char] || char);

        return transformedText2;
    },
    text => {
        const charStyles = {
            'a': '𝔞', 'b': '𝔟', 'c': '𝔠', 'd': '𝔡', 'e': '𝔢',
            'f': '𝔣', 'g': '𝔤', 'h': '𝔥', 'i': '𝔦', 'j': '𝔧',
            'k': '𝔨', 'l': '𝔩', 'm': '𝔪', 'n': '𝔫', 'o': '𝔬',
            'p': '𝔭', 'q': '𝔮', 'r': '𝔯', 's': '𝔰', 't': '𝔱',
            'u': '𝔲', 'v': '𝔳', 'w': '𝔴', 'x': '𝔵', 'y': '𝔶', 'z': '𝔷',
            'A': '𝔄', 'B': '𝔅', 'C': 'ℭ', 'D': '𝔇', 'E': '𝔈',
            'F': '𝔉', 'G': '𝔊', 'H': 'ℌ', 'I': 'ℑ', 'J': '𝔍',
            'K': '𝔎', 'L': '𝔏', 'M': '𝔐', 'N': '𝔑', 'O': '𝔒',
            'P': '𝔓', 'Q': '𝔔', 'R': 'ℜ', 'S': '𝔖', 'T': '𝔗',
            'U': '𝔘', 'V': '𝔙', 'W': '𝔚', 'X': '𝔛', 'Y': '𝔜', 'Z': 'ℨ'
        };

        const transformedChar = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedChar;
    },
    text => {
        const charStyles = {
            'a': '𝖆', 'b': '𝖇', 'c': '𝖈', 'd': '𝖉', 'e': '𝖊',
            'f': '𝖋', 'g': '𝖌', 'h': '𝖍', 'i': '𝖎', 'j': '𝖏',
            'k': '𝖏', 'l': '𝖑', 'm': '𝖒', 'n': '𝖓', 'o': '𝖔',
            'p': '𝖕', 'q': '𝖖', 'r': '𝖗', 's': '𝖘', 't': '𝖙',
            'u': '𝖚', 'v': '𝖛', 'w': '𝖜', 'x': '𝖝', 'y': '𝖞', 'z': '𝖟',
            'A': '𝕬', 'B': '𝕭', 'C': '𝕮', 'D': '𝕯', 'E': '𝕰',
            'F': '𝕱', 'G': '𝕲', 'H': '𝕳', 'I': '𝕴', 'J': '𝕵',
            'K': '𝕶', 'L': '𝕷', 'M': '𝕸', 'N': '𝕹', 'O': '𝕺',
            'P': '𝕻', 'Q': '𝕼', 'R': '𝕽', 'S': '𝕾', 'T': '𝕿',
            'U': '𝕌', 'V': '𝕽', 'W': '𝕎', 'X': '𝕏', 'Y': '𝕐', 'Z': '𝕏'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ａ', 'b': 'в', 'c': '𝔠', 'd': '𝐝', 'e': 'є',
            'f': 'Ŧ', 'g': 'g', 'h': 'Ĥ', 'i': 'ι', 'j': '𝕁',
            'k': '𝐊', 'l': '𝕃', 'm': 'Ⓜ', 'n': 'Ⓝ', 'o': '𝓸',
            'p': 'ρ', 'q': '𝕢', 'r': 'я', 's': 'Ⓢ', 't': '𝕥',
            'u': 'υ', 'v': 'ｖ', 'w': 'ω', 'x': '乂', 'y': 'у', 'z': '𝐙',
            'A': '𝔸', 'B': 'Ｂ', 'C': 'ｃ', 'D': 'ᵈ', 'E': '𝐞',
            'F': 'ƒ', 'G': 'Ꮆ', 'H': '𝐇', 'I': '𝕚', 'J': 'י',
            'K': '𝐊', 'L': '𝐋', 'M': '𝐌', 'N': '𝕹', 'O': '𝕺',
            'P': 'Ⓟ', 'Q': 'ř', 'R': '丂', 'S': '𝓣', 'T': '𝔗',
            'U': '𝕌', 'V': '𝓋', 'W': '𝓦', 'X': 'x', 'Y': 'Ⓨ', 'Z': '𝐙'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': '𝓪', 'b': '𝓫', 'c': '𝓬', 'd': '𝓭', 'e': '𝓮',
            'f': '𝓯', 'g': '𝓰', 'h': '𝓱', 'i': '𝓲', 'j': '𝔧',
            'k': '𝓴', 'l': '𝓵', 'm': '𝓶', 'n': '𝓷', 'o': '𝓸',
            'p': '𝓹', 'q': '𝕹', 'r': '𝓻', 's': '𝓼', 't': '𝓽',
            'u': '𝓾', 'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝔂', 'z': '𝔃',
            'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔',
            'F': '𝓕', 'G': '𝓖', 'H': '𝓗', 'I': '𝓘', 'J': '𝓙',
            'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝', 'O': '𝓞',
            'P': '𝓟', 'Q': '𝕠', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣',
            'U': '𝓤', 'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝒻',
            'f': '𝑔', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿',
            'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜',
            'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉',
            'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏',
            'A': '𝒜', 'B': '𝐵', 'C': '𝒞', 'D': '𝒟', 'E': '𝐸',
            'F': '𝐹', 'G': '𝐺', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥',
            'K': '𝒦', 'L': '𝒫', 'M': '𝑀', 'N': '𝒩', 'O': '𝒪',
            'P': '𝒫', 'Q': '𝒬', 'R': '𝑅', 'S': '𝒮', 'T': '𝒯',
            'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': '𝕒', 'b': '𝕓', 'c': '𝕔', 'd': '𝕕', 'e': '𝕖',
            'f': '𝕗', 'g': '𝕘', 'h': '𝕙', 'i': '𝕚', 'j': '𝕛',
            'k': '𝕜', 'l': '𝕝', 'm': '𝕞', 'n': '𝕟', 'o': '𝕠',
            'p': '𝕡', 'q': '𝕢', 'r': '𝕣', 's': '𝕤', 't': '𝕥',
            'u': '𝕦', 'v': '𝕧', 'w': '𝕨', 'x': '𝕩', 'y': '𝕪', 'z': '𝕫',
            'A': '𝔸', 'B': '𝔹', 'C': 'ℂ', 'D': '𝔻', 'E': '𝔼',
            'F': '𝔽', 'G': '𝔾', 'H': 'ℍ', 'I': '𝕀', 'J': '𝕁',
            'K': '𝕂', 'L': '𝕃', 'M': '𝕄', 'N': 'ℕ', 'O': '𝕆',
            'P': '𝕃', 'Q': 'ℚ', 'R': 'ℝ', 'S': '𝕊', 'T': '𝕋',
            'U': '𝕌', 'V': '𝕍', 'W': '𝕎', 'X': '𝕏', 'Y': '𝕐', 'Z': 'ℤ'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ꪖ', 'b': '᥇', 'c': 'ᥴ', 'd': 'ᦔ', 'e': 'ꫀ',
            'f': 'ᠻ', 'g': 'ᧁ', 'h': 'ꫝ', 'i': '꠸', 'j': '꠹',
            'k': 'ᛕ', 'l': 'ꪶ', 'm': 'ꪑ', 'n': 'ꪀ', 'o': 'ꪮ',
            'p': 'ρ', 'q': 'ꪇ', 'r': '᥅', 's': 'ᦓ', 't': 'ꪻ',
            'u': 'ꪊ', 'v': 'ꪜ', 'w': '᭙', 'x': '᥊', 'y': 'ꪗ', 'z': 'ƺ',
            'A': 'ꪖ', 'B': '᥇', 'C': 'ᥴ', 'D': 'ᦔ', 'E': 'ꫀ',
            'F': 'ᠻ', 'G': 'ᧁ', 'H': 'ꫝ', 'I': '꠸', 'J': '꠹',
            'K': 'ᛕ', 'L': 'ꪶ', 'M': 'ꪑ', 'N': 'ꪀ', 'O': 'ꪮ',
            'P': 'ρ', 'Q': 'ꪇ', 'R': '᥅', 'S': 'ᦓ', 'T': 'ꪻ',
            'U': 'ꪊ', 'V': 'ꪜ', 'W': '᭙', 'X': '᥊', 'Y': 'ꪗ', 'Z': 'ƺ'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ɑ', 'b': 'ϐ', 'c': 'ꫀ', 'd': 'ꪀ', 'e': 'ꪱ',
            'f': 'ꩇ', 'g': 'ᨵ', 'h': 'h', 'i': 'ׁׅ֮', 'j': 'ꭈ',
            'k': 'ƙ', 'l': '℘', 'm': 'ᥣ', 'n': 'n', 'o': 'o',
            'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't',
            'u': 'υ', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z',
            'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E',
            'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J',
            'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O',
            'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T',
            'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },

    text => {
        const charStyles = {
            'a': '𓂅', 'b': '✦', 'c': '✧', 'd': '⊹', 'e': '⋆',
            'f': '⌕', 'g': 'ꗃ', 'h': '⋈', 'i': 'ഒ', 'j': '୨',
            'k': '୧', 'l': '⌯', 'm': '﹅', 'n': '﹆', 'o': 'ଘ',
            'p': 'ꕤ', 'q': 'ꔛ', 'r': '𓏲', 's': 'ָ', 't': 'ǂ',
            'u': '𓍼', 'v': 'ᯅ', 'w': 'ꮺ', 'x': '⌗', 'y': 'ꉂ', 'z': 'ᨒ',
            'A': '𝓐', 'B': 'ℬ', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔',
            'F': '𝓕', 'G': '𝓖', 'H': '𝓗', 'I': '𝓘', 'J': '𝓙',
            'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝', 'O': '𝓞',
            'P': '𝓟', 'Q': '𝑄', 'R': 'ℛ', 'S': '𝓢', 'T': '𝓣',
            'U': '𝓤', 'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ꍏ', 'b': 'ꌃ', 'c': 'ꉓ', 'd': 'ꀸ', 'e': 'ꍟ',
            'f': 'ꎇ', 'g': 'ꁅ', 'h': 'ꃅ', 'i': 'ꀤ', 'j': 'ꀭ',
            'k': 'ꀘ', 'l': '꒒', 'm': 'ꂵ', 'n': 'ꈤ', 'o': 'ꂦ',
            'p': 'ꉣ', 'q': 'ꆰ', 'r': 'ꋪ', 's': 'ꌗ', 't': '꓄',
            'u': 'ꀎ', 'v': 'ꃴ', 'w': 'ꅏ', 'x': 'ꊼ', 'y': 'ꌩ', 'z': 'ꁴ',
            'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔',
            'F': '𝓕', 'G': '𝓖', 'H': '𝓗', 'I': '𝓘', 'J': '𝓙',
            'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝', 'O': '𝓞',
            'P': '𝓟', 'Q': '𝑄', 'R': 'ℛ', 'S': '𝓢', 'T': '𝓣',
            'U': '𝓤', 'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒',
            'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿',
            'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '🍩',
            'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉',
            'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏',
            'A': '𝒜', 'B': '𝐵', 'C': '𝒞', 'D': '𝒟', 'E': '𝐸',
            'F': '𝐹', 'G': '𝒢', 'H': '𝐻', 'I': '𝑰', 'J': '𝒥',
            'K': '𝒦', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩', 'O': '♡',
            'P': '𝒫', 'Q': '𝒬', 'R': '𝑹', 'S': '𝒮', 'T': '𝒯',
            'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ᴀ', 'b': 'ʙ', 'c': 'ᴄ', 'd': 'ᴅ', 'e': 'ᴇ',
            'f': 'ꜰ', 'g': 'ɢ', 'h': 'ʜ', 'i': 'ɪ', 'j': 'ᴊ',
            'k': 'ᴋ', 'l': 'ʟ', 'm': 'ᴍ', 'n': 'ɴ', 'o': 'ᴏ',
            'p': 'ᴘ', 'q': 'Q', 'r': 'ʳ', 's': 'ꜱ', 't': 'ᴛ',
            'u': 'ᴜ', 'v': 'ᴠ', 'w': 'ᴡ', 'x': 'x', 'y': 'ʏ', 'z': 'ᴢ',
            'A': 'ᴀ', 'B': 'ʙ', 'C': 'ᴄ', 'D': 'ᴅ', 'E': 'ᴇ',
            'F': 'ꜰ', 'G': 'ɢ', 'H': 'ʜ', 'I': 'ɪ', 'J': 'ᴊ',
            'K': 'ᴋ', 'L': 'ʟ', 'M': 'ᴍ', 'N': 'ɴ', 'O': 'ᴏ',
            'P': 'ᴘ', 'Q': 'Q', 'R': 'ʳ', 'S': 'ꜱ', 'T': 'ᴛ',
            'U': 'ᴜ', 'V': 'ᴠ', 'W': 'ᴡ', 'X': 'x', 'Y': 'ʏ', 'Z': 'ᴢ'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },

    text => {
        const charStyles = {
            'A': '∀', 'B': '𐌁', 'C': 'Ↄ', 'D': 'ᗡ', 'E': 'Ǝ',
            'F': 'Ⅎ', 'G': 'Ꮹ', 'H': 'H', 'I': 'I', 'J': 'ⅉ',
            'K': 'ʞ', 'L': '⅃', 'M': 'W', 'N': 'N', 'O': 'O',
            'P': 'Ƥ', 'Q': 'ꝗ', 'R': 'ᴚ', 'S': 'S', 'T': '⊥',
            'U': '∩', 'V': 'Λ', 'W': 'M', 'X': 'X', 'Y': '⅄', 'Z': 'Z',
            'a': 'ɐ', 'b': 'q', 'c': 'ɔ', 'd': 'p', 'e': 'ǝ',
            'f': 'ɟ', 'g': 'ƃ', 'h': 'ɥ', 'i': 'ᴉ', 'j': 'ɾ',
            'k': 'ʞ', 'l': 'l', 'm': 'ʍ', 'n': 'u', 'o': 'o',
            'p': 'd', 'q': 'b', 'r': 'ɹ', 's': 's', 't': 'ʇ',
            'u': 'n', 'v': 'ʌ', 'w': 'ʍ', 'x': 'x', 'y': 'ʎ', 'z': 'z'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] || char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': '🄰', 'b': '🄱', 'c': '🄲', 'd': '🄳', 'e': '🄴',
            'f': '🄵', 'g': '🄶', 'h': '🄷', 'i': '🄸', 'j': '🄹',
            'k': '🄺', 'l': '🄻', 'm': '🄼', 'n': '🄽', 'o': '🄾',
            'p': '🄿', 'q': '🅀', 'r': '🅁', 's': '🅂', 't': '🅃',
            'u': '🅄', 'v': '🅅', 'w': '🅆', 'x': '🅇', 'y': '🅈', 'z': '🅉',
            'A': '🄰', 'B': '🄱', 'C': '🄲', 'D': '🄳', 'E': '🄴',
            'F': '🄵', 'G': '🄶', 'H': '🄷', 'I': '🄸', 'J': '🄹',
            'K': '🄺', 'L': '🄻', 'M': '🄼', 'N': '🄽', 'O': '🄾',
            'P': '🄿', 'Q': '🅀', 'R': '🅁', 'S': '🅂', 'T': '🅃',
            'U': '🅄', 'V': '🅅', 'W': '🅆', 'X': '🅇', 'Y': '🅈', 'Z': '🅉'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] ? ` ${charStyles[char]} ` : char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ƹ', 'b': 'ʏ', 'c': 'x', 'd': 'w', 'e': 'v',
            'f': 'u', 'g': 'ƚ', 'h': 'ꙅ', 'i': 'ɿ', 'j': 'p',
            'k': 'q', 'l': 'ᴎ', 'm': 'm', 'n': '|', 'o': 'ʞ',
            'p': 'ꞁ', 'q': 'i', 'r': 'ʜ', 's': 'ǫ', 't': 'Ꮈ',
            'u': 'ɘ', 'v': 'b', 'w': 'ɔ', 'x': 'd', 'y': 'ɒ', 'z': ' ',
            'A': 'Ƹ', 'B': 'Y', 'C': 'X', 'D': 'W', 'E': 'V',
            'F': 'U', 'G': 'T', 'H': 'Ꙅ', 'I': 'Я', 'J': 'Ọ',
            'K': 'ꟼ', 'L': 'O', 'M': 'Ͷ', 'N': 'M', 'O': '⅃',
            'P': '⋊', 'Q': 'Ⴑ', 'R': 'H', 'S': 'Ꭾ', 'T': 'ꟻ',
            'U': 'Ǝ', 'V': 'ᗡ', 'W': 'Ɔ', 'X': 'ᙠ', 'Y': 'A', 'Z': ' '
        };

        // Transforming the text and adding spaces
        const transformedText = text.replace(/./g, char =>
            charStyles[char] ? `${charStyles[char]}` : char
        );

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ȁ̸̲̫̼̞̥͈̤͚̻', 'b': 'b̴͚̩̞͉̠͓̗͕̆̄͂̒̎̒̂̄̉͝', 'c': 'c̷̮͛̓͋͆', 'd': 'ḑ̶̛̠̜̭̖͛̉̕͠',
            'e': 'e̶̢̖̟͕̻͍͓̜͂͗̓̂̈́̒̀́͜', 'f': 'f̷̧̯̦͙̝̥̐', 'g': 'ḡ̸̨̤̬̖̳̘͓͋̿̑̈̔͑̌̀',
            'h': 'ḧ̴̪͗̏͂̀̈́̊̕͝', 'i': 'i̴̡̫̦̭͈̲͐́̊̈͂̇̈́͐̔̊', 'j': 'j̶̻̫̦̜͓̋͛̐̾͋ͅ',
            'k': 'ḳ̷̮̘͑͘ͅ', 'l': 'l̵̨̹̬͚̟͖̃̒̏̈́̈́̐̈', 'm': 'm̶̬̤͓̼͙̯̉͊̄͂̚',
            'n': 'n̸̘̩̎̓', 'o': 'ơ̴̢̖̰̳̮̫̝̟̒̐̍͒ͅ', 'p': 'p̵̩̲̱̺̯̰̤͐̾̈́̔̉̅',
            'q': 'q̴̤̜̪̼̉̈́́̏̉͘', 'r': 'ŗ̷̦̙͖̭̙̦͎͐̌̋̅́̈̃̚͝͠', 's': 'ş̸͔̳͉̦̪̝̠̰̒͐̄̉̎́͆',
            't': 't̴̢̛͇̬͕͙̞͔̰̠̏͑͂', 'u': 'ư̵͙̻͙̑́̕͝', 'v': 'v̵̨̙̣͒̓',
            'w': 'w̸͎̥̮̦̭͙̤̤̋͆̈́̈͜͝͠', 'x': 'x̷̡̳̺̑̈́̍̍́̉̈́͂͜͠', 'y': 'y̴̧̲͗̀̽͌̓̕',
            'z': 'z̴̩̪̟̙͈̏',

            'A': 'A̴̞͕̒', 'B': 'B̷͓̬͖̱̳̣̞̘̂̓̿͒̕͠', 'C': 'C̵̼̺̘̾͛̂̽͌͝͝', 'D': 'D̸̝̤̻̮̖̓͌̔̍̈́̕͝',
            'E': 'E̸̡͓̜̞̻͉̻͑͐̾̒͛ͅ', 'F': 'F̷̨͌̎̈̆͂̇', 'G': 'G̸̛̻͈̈́̓̎́͋́͝',
            'H': 'H̶̨̄̓́̍̔̕', 'I': 'I̵̯͆͐̀̈́̽̈́̆̈̇͝', 'J': 'J̶͓̈́̾͗̾͌̀̾̊͝',
            'K': 'K̵̛̭̥͖̳̞̜̈́̓͗̾̏̃͌͒̕', 'L': 'Ļ̸̡͙̙̯͖͉̼̝̃͐ͅ', 'M': 'M̶͈͔̥̥͚͉̞̻͛͌͛̾̀̕',
            'N': 'Ń̸̰̰', 'O': 'O̶̝̼̗͇̝̫̩̖̘͐̈́͌', 'P': 'P̴̧̢̛͍͎̓̂̎̈́̆́̕',
            'Q': 'Q̶̦͙̬̟̐͊ͅ', 'R': 'Ȓ̵̙͚͒͒', 'S': 'S̵̢̟̰͍̟̲͐͜',
            'T': 'T̸̜̖̫̼͙̦͙̙̃̓', 'U': 'Ư̷̼̜̊̔̈́̿̒̔', 'V': 'V̵̧̨̹̯̀͝͝͠',
            'W': 'W̸͙̳̪͍̠̹̤̭̖̏̈́̆̽̔̃̂', 'X': 'X̵̦͈̠̆̑̏͘', 'Y': 'Ŷ̶͔͎̙̗̲̺̟̈̾͂̅̈́̃̋̕͜͝',
            'Z': 'Ź̵̲̮̲̽̍̾́̏̂̿̾̋'
        };

        const transformedText = text.replace(/./g, char =>
            charStyles[char] ? `${charStyles[char]}` : char
        );

        return transformedText;
    },
    text => {
        const emojiStyles = {
            'a': '🅰', 'b': '🅱', 'c': '🅲', 'd': '🅳',
            'e': '🅴', 'f': '🅵', 'g': '🅶', 'h': '🅷',
            'i': '🅸', 'j': '🅹', 'k': '🅺', 'l': '🅻',
            'm': '🅼', 'n': '🅽', 'o': '🅾', 'p': '🅿',
            'q': '🆀', 'r': '🆁', 's': '🆂', 't': '🆃',
            'u': '🆄', 'v': '🆅', 'w': '🆆', 'x': '🆇',
            'y': '🆈', 'z': '🆉',
            'A': '🅰', 'B': '🅱', 'C': '🅲', 'D': '🅳',
            'E': '🅴', 'F': '🅵', 'G': '🅶', 'H': '🅷',
            'I': '🅸', 'J': '🅹', 'K': '🅺', 'L': '🅻',
            'M': '🅼', 'N': '🅽', 'O': '🅾', 'P': '🅿',
            'Q': '🆀', 'R': '🆁', 'S': '🆂', 'T': '🆃',
            'U': '🆄', 'V': '🆅', 'W': '🆆', 'X': '🆇',
            'Y': '🆈', 'Z': '🆉'
        };

        const transformedText = text.replace(/./g, char =>
            emojiStyles[char] ? `${emojiStyles[char]}` : char
        );

        return transformedText;
    },
    text => {
        const styledFonts = {
            'a': 'ₐ', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'ₑ',
            'f': 'f', 'g': 'g', 'h': 'ₕ', 'i': 'ᵢ', 'j': 'ⱼ',
            'k': 'ₖ', 'l': 'ₗ', 'm': 'ₘ', 'n': 'ₙ', 'o': 'ₒ',
            'p': 'ₚ', 'q': 'q', 'r': 'ᵣ', 's': 'ₛ', 't': 'ₜ',
            'u': 'ᵤ', 'v': 'ᵥ', 'w': 'w', 'x': 'ₓ', 'y': 'y', 'z': 'z',
            'A': 'ₐ', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'ₑ',
            'F': 'F', 'G': 'G', 'H': 'H', 'I': 'ᵢ', 'J': 'J',
            'K': 'ₖ', 'L': 'ₗ', 'M': 'ₘ', 'N': 'ₙ', 'O': 'O',
            'P': 'P', 'Q': 'Q', 'R': 'ᵣ', 'S': 'S', 'T': 'ₜ',
            'U': 'ᵤ', 'V': 'ᵥ', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z'
        };

        const transformedText = text.replace(/./g, char =>
            styledFonts[char] ? `${styledFonts[char]}` : char
        );

        return transformedText;
    },
    text => {
        const styledFonts = {
            'a': 'ᵃ', 'b': 'ᵇ', 'c': 'ᶜ', 'd': 'ᵈ', 'e': 'ᵉ',
            'f': 'ᶠ', 'g': 'ᵍ', 'h': 'ʰ', 'i': 'ⁱ', 'j': 'ʲ',
            'k': 'ᵏ', 'l': 'ˡ', 'm': 'ᵐ', 'n': 'ⁿ', 'o': 'ᵒ',
            'p': 'ᵖ', 'q': 'q', 'r': 'ʳ', 's': 'ˢ', 't': 'ᵗ',
            'u': 'ᵘ', 'v': 'ᵛ', 'w': 'ʷ', 'x': 'ˣ', 'y': 'ʸ', 'z': 'ᶻ',
            'A': 'ᴬ', 'B': 'ᴮ', 'C': 'ᶜ', 'D': 'ᴰ', 'E': 'ᴱ',
            'F': 'ᶠ', 'G': 'ᴳ', 'H': 'ᴴ', 'I': 'ᴵ', 'J': 'ᴶ',
            'K': 'ᴷ', 'L': 'ᴸ', 'M': 'ᴹ', 'N': 'ᴺ', 'O': 'ᴼ',
            'P': 'ᴾ', 'Q': 'Q', 'R': 'ᴿ', 'S': 'ˢ', 'T': 'ᵀ',
            'U': 'ᵁ', 'V': 'ⱽ', 'W': 'ᵂ', 'X': 'ˣ', 'Y': 'ʸ', 'Z': 'ᶻ'
        };

        const transformedText = text.replace(/./g, char =>
            styledFonts[char] ? `${styledFonts[char]}` : char
        );

        return transformedText;
    },
    text => {
        const wrapInBrackets = (char) => `[̲̅${char}]`;
        const transformedText = text.split('').map(wrapInBrackets).join('');
        return transformedText;
    },
    text => {
        const addSpecialCharacter = (char) => `${char}҉`;
        const transformedText = text.split('').map(addSpecialCharacter).join('');
        return transformedText;
    },
    text => {
        const charStyles = {
            'a': '₳', 'b': '฿', 'c': '₵', 'd': 'Đ', 'e': 'Ɇ',
            'f': '₣', 'g': '₲', 'h': 'Ⱨ', 'i': 'ł', 'j': 'J',
            'k': '₭', 'l': 'Ⱡ', 'm': '₥', 'n': '₦', 'o': 'Ø',
            'p': '₱', 'q': 'Q', 'r': 'Ɽ', 's': '₴', 't': '₮',
            'u': 'Ʉ', 'v': 'V', 'w': '₩', 'x': 'Ӿ', 'y': 'Ɏ', 'z': 'Ⱬ',
            'A': '₳', 'B': '฿', 'C': '₵', 'D': 'Đ', 'E': 'Ɇ',
            'F': '₣', 'G': '₲', 'H': 'Ⱨ', 'I': 'ł', 'J': 'J',
            'K': '₭', 'L': 'Ⱡ', 'M': '₥', 'N': '₦', 'O': 'Ø',
            'P': '₱', 'Q': 'Q', 'R': 'Ɽ', 'S': '₴', 'T': '₮',
            'U': 'Ʉ', 'V': 'V', 'W': '₩', 'X': 'Ӿ', 'Y': 'Ɏ', 'Z': 'Ⱬ'
        };

        const transformedText = text.split('').map(char => charStyles[char] || char).join('');

        return transformedText;
    },
    text => {
        const strikethrough = '\u0336';
        const transformedText = text.split('').map(char => char + strikethrough).join('');
        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ค', 'b': 'β', 'c': 'ς', 'd': 'ｄ', 'e': 'є', 'f': 'ƒ',
            'g': '𝐆', 'h': '𝔥', 'i': '𝕀', 'j': 'נ', 'k': '𝕂', 'l': 'Ⓛ',
            'm': 'м', 'n': '𝓷', 'o': '𝑜', 'p': 'ק', 'q': 'ᵠ', 'r': 'г',
            's': '𝐬', 't': 'т', 'u': 'Ⓤ', 'v': 'Ѷ', 'w': 'Ⓦ', 'x': '𝔁',
            'y': 'у', 'z': '𝓏',
            'A': '𝒶', 'B': 'в', 'C': 'Č', 'D': '๔', 'E': 'έ', 'F': '千',
            'G': 'ⓖ', 'H': '𝐇', 'I': 'ι', 'J': 'ʲ', 'K': 'Ќ', 'L': '𝓁',
            'M': '𝐌', 'N': '𝕟', 'O': 'Ỗ', 'P': 'ⓟ', 'Q': '𝔮', 'R': 'Ⓡ',
            'S': 'ร', 'T': '𝐓', 'U': '𝐔', 'V': 'ѵ', 'W': 'ⓦ', 'X': 'ⓧ',
            'Y': 'ⓨ', 'Z': '𝐙'
        };

        const transformedText = text.split('').map(char => charStyles[char] || char).join('');

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ค', 'b': 'ｂ', 'c': 'Ｃ', 'd': '𝐃', 'e': '𝕖', 'f': 'ｆ',
            'g': 'ᵍ', 'h': 'Ĥ', 'i': '𝕀', 'j': '𝕁', 'k': '𝕂', 'l': '𝕃',
            'm': 'м', 'n': '𝐍', 'o': '𝕠', 'p': 'Ｐ', 'q': 'ᵠ', 'r': '尺',
            's': '𝓈', 't': 'ⓣ', 'u': 'υ', 'v': '𝓿', 'w': '𝓦', 'x': 'ｘ',
            'y': '𝔶', 'z': 'Ｚ',
            'A': 'Ⓐ', 'B': '𝓑', 'C': 'ᶜ', 'D': 'đ', 'E': 'ᗴ', 'F': 'ℱ',
            'G': '𝑔', 'H': '𝐇', 'I': 'ⓘ', 'J': 'ｊ', 'K': '𝕂', 'L': '𝓁',
            'M': 'ｍ', 'N': '𝓃', 'O': 'ᗝ', 'P': '𝓅', 'Q': '𝓺', 'R': 'я',
            'S': 'Ⓢ', 'T': 'т', 'U': 'Ｕ', 'V': '𝕍', 'W': 'Ŵ', 'X': 'Ｘ',
            'Y': '𝐘', 'Z': '𝐳'
        };

        const transformedText = text.split('').map(char => charStyles[char] || char).join('');

        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ค', 'b': '𝕓', 'c': '¢', 'd': '𝓭', 'e': '𝑒', 'f': '𝔣',
            'g': '𝔤', 'h': 'Ĥ', 'i': 'ⓘ', 'j': '𝐉', 'k': '𝐊', 'l': 'l',
            'm': 'ｍ', 'n': '𝓷', 'o': 'σ', 'p': 'Ƥ', 'q': 'Ⓠ', 'r': 'я',
            's': 'ѕ', 't': '𝔱', 'u': 'Ữ', 'v': 'ν', 'w': 'ω', 'x': 'Ж',
            'y': '𝓨', 'z': '𝐳',
            'A': '𝐚', 'B': '𝐁', 'C': 'ς', 'D': 'Ⓓ', 'E': '𝔢', 'F': 'ƒ',
            'G': 'Ꮆ', 'H': '𝔥', 'I': '𝔦', 'J': '𝔧', 'K': 'ⓚ', 'L': '𝐋',
            'M': 'м', 'N': 'ℕ', 'O': '𝕆', 'P': 'Ⓟ', 'Q': 'ⓠ', 'R': 'ｒ',
            'S': 'Ŝ', 'T': '𝓣', 'U': '𝓊', 'V': 'ν', 'W': '山', 'X': '𝕩',
            'Y': '𝐘', 'Z': 'z'
        };

        const transformedText = text.split('').map(char => charStyles[char] || char).join('');

        return transformedText;
    },
    text => {
        const charStyles = {
            'A': '𝐀', 'B': 'Ⓑ', 'C': 'ᑕ', 'D': 'Ď', 'E': 'ᗴ', 'F': '𝐟',
            'G': 'Ğ', 'H': 'ⓗ', 'I': 'เ', 'J': 'ｊ', 'K': 'Ҝ', 'L': '𝓛',
            'M': '爪', 'N': 'ᶰ', 'O': '๏', 'P': '𝐩', 'Q': 'ᵠ', 'R': '𝐑',
            'S': 'Ｓ', 'T': '𝓉', 'U': 'Ⓤ', 'V': 'V', 'W': '𝐖', 'X': '𝔵',
            'Y': 'Ў', 'Z': '乙',
            'a': '𝓐', 'b': 'Ｂ', 'c': '𝕔', 'd': 'Ď', 'e': 'Ⓔ', 'f': 'Ŧ',
            'g': 'ق', 'h': '𝒽', 'i': '𝓲', 'j': 'Ĵ', 'k': '𝓀', 'l': 'Ĺ',
            'm': '𝐦', 'n': 'Ⓝ', 'o': 'ό', 'p': '𝐩', 'q': '𝐐', 'r': 'ŕ',
            's': 's', 't': '𝐓', 'u': '𝐮', 'v': '𝐕', 'w': 'ώ', 'x': 'x',
            'y': '𝔶', 'z': '𝓩'
        };

        const transformedText = text.split('').map(char => charStyles[char] || char).join('');

        return transformedText;
    },
    text => {
        const addStyle = (char) => char + '͓̽';
        const transformedText = text.split('').map(addStyle).join('');
        return transformedText;
    },
    text => {
        const charStyles = {
            'a': 'ａ', 'b': 'ｂ', 'c': 'ｃ', 'd': 'ｄ', 'e': 'ｅ',
            'f': 'ｆ', 'g': 'ｇ', 'h': 'ｈ', 'i': 'ｉ', 'j': 'ｊ',
            'k': 'ｋ', 'l': 'ｌ', 'm': 'ｍ', 'n': 'ｎ', 'o': 'ｏ',
            'p': 'ｐ', 'q': 'ｑ', 'r': 'ｒ', 's': 'ｓ', 't': 'ｔ',
            'u': 'ｕ', 'v': 'ｖ', 'w': 'ｗ', 'x': 'ｘ', 'y': 'ｙ', 'z': 'ｚ',
            'A': 'Ａ', 'B': 'Ｂ', 'C': 'Ｃ', 'D': 'Ｄ', 'E': 'Ｅ',
            'F': 'Ｆ', 'G': 'Ｇ', 'H': 'Ｈ', 'I': 'Ｉ', 'J': 'Ｊ',
            'K': 'Ｋ', 'L': 'Ｌ', 'M': 'Ｍ', 'N': 'Ｎ', 'O': 'Ｏ',
            'P': 'Ｐ', 'Q': 'Ｑ', 'R': 'Ｒ', 'S': 'Ｓ', 'T': 'Ｔ',
            'U': 'Ｕ', 'V': 'Ｖ', 'W': 'Ｗ', 'X': 'Ｘ', 'Y': 'Ｙ', 'Z': 'Ｚ'
        };

        return text.split('').map(char => charStyles[char] || char).join('');
    },
];

export default fancyTextStyles;