import React from 'react';
import { countries, states } from '../DataFiles/countries';
import { capitalizeWords } from '../utils';

const StepTwo = ({ id = "defaultId", register, watch, handleSubmit, onSubmit, isBusiness, setIsBusiness, showState }) => {
  const selectedCountry = watch('country');

  const handleEntityTypeChange = (e) => {
    const value = e.target.value;
    setIsBusiness(value === "Business");
  };

  return (
    <>
      {id === 'eula' ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='options'>
              <h2>{capitalizeWords(id)} Generator</h2>

              <div className="input-field">
                <label htmlFor="appName">What is your app name? <span style={{ color: '#b2392e' }}>*</span></label>
                <div>
                  <input
                    type="text"
                    id="appName"
                    placeholder='My App'
                    {...register("appName", { required: true })}
                  />
                  <p>e.g. My App</p>
                </div>
              </div>

              <div className="radio-input-field">
                <p>Entity Type <span style={{ color: '#b2392e' }}>*</span></p>
                <div className='radio-input'>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="Business"
                      {...register("entityType", { required: true })}
                      onChange={handleEntityTypeChange}
                    />
                    <span className="radio-checkmark"></span>
                    I'm a Business <br />
                    e.g. Corporation, Limited Liability Company, Non-profit, Partnership, Sole Proprietor
                  </label>

                  {isBusiness && (
                    <div className="business-details">
                      <div className="input-field border-style">
                        <div>
                          <label htmlFor="businessName">What is the name of the business?</label>
                          <input
                            type="text"
                            id="businessName"
                            placeholder='My Company LLC'
                            {...register("businessName", { required: true })}
                          />
                          <p>e.g. My Company LLC</p>
                        </div>
                      </div>
                      <div className="input-field">
                        <label htmlFor="businessAddress">What is the address of the business?</label>
                        <div>
                          <input
                            type="text"
                            id="businessAddress"
                            placeholder='1 Cupertino, CA 95014'
                            {...register("businessAddress", { required: true })}
                          />
                          <p>e.g. 1 Cupertino, CA 95014</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="Individual"
                      {...register("entityType", { required: true })}
                      onChange={handleEntityTypeChange}
                    />
                    <span className="radio-checkmark"></span>
                    I'm an Individual
                  </label>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="country">Enter the country</label>
                <input
                  type="text"
                  id="country"
                  list="countries"
                  autoFocus
                  {...register("country", { required: true })}
                />
                <datalist id="countries">
                  {countries.map((country) => (
                    <option key={country} value={country} />
                  ))}
                </datalist>
              </div>

              {showState && (
                <div className="input-field">
                  <label htmlFor="state">Enter the state <span style={{ color: '#b2392e' }}>*</span></label>
                  <select id="state" {...register("state", { required: true })}>
                    <option value="">Select a state</option>
                    {states.map((state) => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </form>
        </>
      ) : id === 'cookies-policy' ? (
      <>
      <form onSubmit={handleSubmit(onSubmit)}>
            <div className='options'>
              <h2>{capitalizeWords(id)} Generator</h2>

              <div className="input-field">
                <label htmlFor="websiteURL">What is your website URL? <span style={{ color: '#b2392e' }}>*</span></label>
                <div>
                  <input
                    type="text"
                    id="websiteURL"
                    placeholder='http://www.mysite.com'
                    {...register("websiteURL", { required: true })}
                  />
                  <p>e.g. http://www.mysite.com</p>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="websiteName">What is your website name? <span style={{ color: '#b2392e' }}>*</span></label>
                <div>
                  <input
                    type="text"
                    id="websiteName"
                    placeholder='My Site'
                    {...register("websiteName", { required: true })}
                  />
                  <p>e.g. My Site</p>
                </div>
              </div>

              <div className="radio-input-field">
                <p>Entity Type <span style={{ color: '#b2392e' }}>*</span></p>
                <div className='radio-input'>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="Business"
                      {...register("entityType", { required: true })}
                      onChange={handleEntityTypeChange}
                    />
                    <span className="radio-checkmark"></span>
                    I'm a Business <br />
                    e.g. Corporation, Limited Liability Company, Non-profit, Partnership, Sole Proprietor
                  </label>

                  {isBusiness && (
                    <div className="business-details">
                      <div className="input-field border-style">
                        <div>
                          <label htmlFor="businessName">What is the name of the business?</label>
                          <input
                            type="text"
                            id="businessName"
                            placeholder='My Company LLC'
                            {...register("businessName", { required: true })}
                          />
                          <p>e.g. My Company LLC</p>
                        </div>
                      </div>
                      <div className="input-field">
                        <label htmlFor="businessAddress">What is the address of the business?</label>
                        <div>
                          <input
                            type="text"
                            id="businessAddress"
                            placeholder='1 Cupertino, CA 95014'
                            {...register("businessAddress", { required: true })}
                          />
                          <p>e.g. 1 Cupertino, CA 95014</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="Individual"
                      {...register("entityType", { required: true })}
                      onChange={handleEntityTypeChange}
                    />
                    <span className="radio-checkmark"></span>
                    I'm an Individual
                  </label>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="country">Enter the country</label>
                <input
                  type="text"
                  id="country"
                  list="countries"
                  autoFocus
                  {...register("country", { required: true })}
                />
                <datalist id="countries">
                  {countries.map((country) => (
                    <option key={country} value={country} />
                  ))}
                </datalist>
              </div>

              {showState && (
                <div className="input-field">
                  <label htmlFor="state">Enter the state <span style={{ color: '#b2392e' }}>*</span></label>
                  <select id="state" {...register("state", { required: true })}>
                    <option value="">Select a state</option>
                    {states.map((state) => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </form>
      </> 
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='options'>
              <h2>{capitalizeWords(id)} Generator</h2>

              <div className="input-field">
                <label htmlFor="websiteURL">What is your website URL? <span style={{ color: '#b2392e' }}>*</span></label>
                <div>
                  <input
                    type="text"
                    id="websiteURL"
                    placeholder='http://www.mysite.com'
                    {...register("websiteURL", { required: true })}
                  />
                  <p>e.g. http://www.mysite.com</p>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="websiteName">What is your website name? <span style={{ color: '#b2392e' }}>*</span></label>
                <div>
                  <input
                    type="text"
                    id="websiteName"
                    placeholder='My Site'
                    {...register("websiteName", { required: true })}
                  />
                  <p>e.g. My Site</p>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="appName">What is your app name? <span style={{ color: '#b2392e' }}>*</span></label>
                <div>
                  <input
                    type="text"
                    id="appName"
                    placeholder='My App'
                    {...register("appName", { required: true })}
                  />
                  <p>e.g. My App</p>
                </div>
              </div>

              <div className="radio-input-field">
                <p>Entity Type <span style={{ color: '#b2392e' }}>*</span></p>
                <div className='radio-input'>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="Business"
                      {...register("entityType", { required: true })}
                      onChange={handleEntityTypeChange}
                    />
                    <span className="radio-checkmark"></span>
                    I'm a Business <br />
                    e.g. Corporation, Limited Liability Company, Non-profit, Partnership, Sole Proprietor
                  </label>

                  {isBusiness && (
                    <div className="business-details">
                      <div className="input-field border-style">
                        <div>
                          <label htmlFor="businessName">What is the name of the business?</label>
                          <input
                            type="text"
                            id="businessName"
                            placeholder='My Company LLC'
                            {...register("businessName", { required: true })}
                          />
                          <p>e.g. My Company LLC</p>
                        </div>
                      </div>
                      <div className="input-field">
                        <label htmlFor="businessAddress">What is the address of the business?</label>
                        <div>
                          <input
                            type="text"
                            id="businessAddress"
                            placeholder='1 Cupertino, CA 95014'
                            {...register("businessAddress", { required: true })}
                          />
                          <p>e.g. 1 Cupertino, CA 95014</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="Individual"
                      {...register("entityType", { required: true })}
                      onChange={handleEntityTypeChange}
                    />
                    <span className="radio-checkmark"></span>
                    I'm an Individual
                  </label>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="country">Enter the country</label>
                <input
                  type="text"
                  id="country"
                  list="countries"
                  autoFocus
                  {...register("country", { required: true })}
                />
                <datalist id="countries">
                  {countries.map((country) => (
                    <option key={country} value={country} />
                  ))}
                </datalist>
              </div>

              {showState && (
                <div className="input-field">
                  <label htmlFor="state">Enter the state <span style={{ color: '#b2392e' }}>*</span></label>
                  <select id="state" {...register("state", { required: true })}>
                    <option value="">Select a state</option>
                    {states.map((state) => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default StepTwo;