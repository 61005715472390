import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faGear, faCheckDouble, faLock, faShieldHalved, faCookieBite, faCircleExclamation, faRotate } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Style
import '../../assets/PoliciesTermsToolAssets/App.css';

const TermsFeedPage = () => {
  const navigate = useNavigate();

  const cardData = [
    { title: "Privacy Policy Generator", id: "privacy-policy", icon: faShieldHalved },
    { title: "Terms & Conditions Generator", id: "terms-and-conditions", icon: faCheckDouble },
    { title: "Cookies Policy Generator", id: "cookies-policy", icon: faCookieBite },
    // { title: "Cookie Consent", id: "cookie-consent", icon: faLock },
    { title: "EULA Generator", id: "eula", icon: faGear },
    { title: "Disclaimer Generator", id: "disclaimer", icon: faCircleExclamation },
    { title: "Return & Refund Policy Generator", id: "return-and-refund-policy", icon: faRotate },
  ];

  const handleCardClick = (id) => {
    console.log(id)
    navigate(`/form/${id}`);
  };

  return (
    <section className="terms-feed-main">
      <div className="container">
        <h1>Welcome to TermsFeed</h1>
        <div className="cards-main">
          <div className='card-main-top'>
            {cardData.slice(0, 1).map((card) => (
              <div
                key={card.id}
                id={card.id}
                className="card"
                onClick={() => handleCardClick(card.id)}
              >
                <FontAwesomeIcon icon={card.icon} />
                <h3>{card.title}</h3>
                <div className='start-up'>
                  <p>Start</p>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            ))}
            <div className="column-container">
              {cardData.slice(1, 3).map((card) => (
                <div
                  key={card.id}
                  id={card.id}
                  className="card"
                  onClick={() => handleCardClick(card.id)}
                >
                  <FontAwesomeIcon icon={card.icon} />
                  <h3>{card.title}</h3>
                  <div className='start-up'>
                    <p>Start</p>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='card-main-bottom'>
            {cardData.slice(3).map((card) => (
              <div
                key={card.id}
                id={card.id}
                className="card"
                onClick={() => handleCardClick(card.id)}
              >
                <FontAwesomeIcon icon={card.icon} />
                <h3>{card.title}</h3>
                <div className='start-up'>
                  <p>Start</p>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Outlet />
    </section>
  );
};

export default TermsFeedPage;